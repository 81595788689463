import _ from 'underscore';
import hasPermission from '../../../../utils/hasPermission';

export default {
    inserted (el, binding, vnode) {
        if (!binding.value) { return; }
        if (_.isArray(binding.value)) {
            _.each(binding.value, perm => {
                if (!hasPermission(perm)) { el.style.display = 'none'; }
            });
        } else if (!hasPermission(binding.value)) {
            el.style.display = 'none';
        }
    }
};
