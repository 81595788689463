<i18n>
{
    "ru": {
        "NoAccess": "Доступ запрещён",
        "Logout": "Войти под другим пользователем",
        "ToSite": "Перейти на публичный сайт",
        "Or": "или"
    },
    "en": {
        "NoAccess": "Access denied",
        "Logout": "Log in as a different user",
        "ToSite": "Go to public website",
        "Or": "or"
    }
}
</i18n>
<template>
    <div :class="$style.wrapper">
        <div :class="$style.inner">
            <h2>{{ $t('NoAccess') }}</h2>
            <p><a :href="logout" @click="localLogout">{{ $t('Logout') }}</a> {{ $t('Or') }} <a href="https://flippingbook.com">{{ $t('ToSite') }}</a></p>
        </div>
    </div>
</template>
<style module>
.wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
}

.inner {
    text-align: center;
}
</style>
<script>
import sd from 'fb-utils/serviceDiscovery';

export default {
    data () {
        return {
            logout: ''
        };
    },
    created () {
        sd.instance().GetApi('generic', 'logon-logout').then(url => (this.logout = url + '?returnUrl=' + encodeURIComponent('https://' + window.location.hostname + this.$route.query.nextUrl)));
    },
    methods: {
        localLogout () {
            sessionStorage.clear();
            localStorage.clear();
            return true;
        }
    }
};
</script>
