let mid = 0;
export default class {
    constructor (options) {
        Object.assign(this, {
            style: 'info',
            text: 'message',
            copyText: null,
            header: null,
            icon: null,
            buttons: [],
            close: true,
            autoClose: 5000
        }, options);
        this.id = mid++;
    }
}
