import ws from 'fb-utils/webservice';
import app from '../../utils/appInstance';
import sd from 'fb-utils/serviceDiscovery';

export default function (appx) {
    ws.setInvalidAccessTokenHandler(() => {
        app().showMessage({
            style: 'danger',
            text: appx.options.locale === 'en' ? 'Invalid access token detected. You might have logged into another account, you IP address has changed, or didn\'t use admin panel for some time. Reloading might help in this situation.'
                : 'Обнаружен невалидный токен доступа. Возможно вы вошли в другой аккаунт, изменился ваш IP адрес или просто админка не использовалась некоторое время. Перезагрузка страницы может решить проблему.',
            autoClose: false,
            buttons: [{
                text: appx.options.locale === 'en' ? 'Reload' : 'Перезагрузить',
                handler: () => window.location.reload()
            }, {
                text: appx.options.locale === 'en' ? 'Soft logout' : 'Мягкий выход',
                handler: () => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                }
            },
            {
                text: appx.options.locale === 'en' ? 'Full logout' : 'Полный выход',
                handler: () => {
                    sessionStorage.clear();
                    localStorage.clear();
                    sd.instance().GetApi('generic', 'logon-logout').then(url => (window.location = url + '?returnUrl=' + encodeURIComponent(window.location)));
                }
            }]
        });
    });
}
