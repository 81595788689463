<template>
    <div
        :class="$style.Wrapper"
        v-on="$listeners"
        :style="{zIndex: zIndex}"
    >
        <slot/>
    </div>
</template>

<style module src="./MdOverlay.css"/>
<script>
let zIndex = 9999;

export default {
    name: 'md-overlay',
    data () {
        return {
            zIndex: zIndex++
        };
    }
};
</script>
