<template>
    <MdOverlay @click.self.stop.prevent="maybeClose">
        <div :class="[$style.Wrapper, contentClass]" :style="{width,height,'max-height': height==='auto'?'90%':null}" @mousedown="x__clickedElement = $event.target" @mouseup="x__clickedElement=null">
            <div :class="$style.Header">
                <h6 :class="$style.Title" v-if="title">{{title}}</h6>
                <div :class="$style.Close" @click="close()"></div>
            </div>
            <slot/>
            <div v-if="$slots.footer" :class="$style.Footer">
                <slot name="footer"/>
            </div>
        </div>
    </MdOverlay>
</template>

<script>
import MdOverlay from 'vue_modules/MdComponents/MdOverlay/MdOverlay';

export default {
    name: 'md-dialog',
    components: {MdOverlay},
    props: {
        title: String,
        contentClass: null,
        width: {
            type: String,
            default: '75%'
        },
        height: {
            type: String,
            default: 'auto'
        },
        preventClose: Boolean,
        preventClickOutsideClose: {
            type: Boolean,
            default: false
        },
        closeResult: {
            default: null
        }
    },
    data: function () {
        return {
            x__clickedElement: null
        };
    },
    methods: {
        close (data) {
            if (!this.preventClose) {
                let p = this;
                while (p.$parent && p.$parent.$parent && !p.$parent.$parent.closeOverlay) {
                    p = p.$parent;
                }
                p.$emit('close', (data === undefined || data === null) ? this.closeResult : data);
                // this.$root.close.apply(this.$root, Array.prototype.slice.apply(arguments));
            }
            // this.$root.close(Array.prototype.slice.apply(arguments));
        },
        maybeClose (e) {
            if (!this.preventClickOutsideClose) {
                if (e.target === this.x__clickedElement || this.x__clickedElement === null) { this.close(); }
                this.x__clickedElement = null;
            }
        },
        memorizeClose (e) {
            this.x__clickedElement = e.target;
        }
    }
};
</script>
<style module>
    @import '../../../css-mixins/colors.css';

    :root{
        --dialog-padding: 2em;
    }

    .Wrapper {
        padding: var(--dialog-padding);
        position: absolute;
        border-radius:3px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        background-color: var(--material-control-background);
    }

    @media (max-width: 700px){
        :root{
            --dialog-padding: .5em;
        }
        .Wrapper{
            position: fixed;
            top:0;
            left:0;
            bottom:0;
            right:0;
            overflow: auto;
            width: auto !important;
            height: auto !important;
        }
    }

    .Title {
        color: var(--material-foreground);
        font-size: 150%;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.0075em;
        flex-grow: 1;
        margin: 0 0 1em 0;
    }

    .Header {
        display: flex;
        align-items: flex-start;
    }

    .Close {
        margin: 0;
        display: block;
        padding: .45em;
        width: 1.2em;
        line-height: 1.2em;
        color: #888;
        cursor: pointer;
        transition: color ease .25s;
    }

    .Close:before {
        display: block;
        content: '×';
        font-size: 170%;
    }

    .Close:hover {
        color: var(--material-accent);
    }

    .Footer {
        border-top: 1px solid var(--material-border);
        background: var(--material-background);
        margin: var(--dialog-padding) calc(0em - var(--dialog-padding)) calc(0em - var(--dialog-padding)) calc(0em - var(--dialog-padding));
        padding: 1em var(--dialog-padding);
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content:flex-end;
        align-items: baseline;
    }
    .Footer > *:first-child {
        margin-left: 0;
    }
    .Footer > * {
        margin-left: .5em;
    }
</style>
