<i18n>
{
    "ru": {
        "titleSymbol": "☭",
        "title": "Управление",
        "search": "Поиск",
        "doSearch": "Искать",
        "settings": "Настройки и выход"
    },
    "en": {
        "titleSymbol": "⚠",
        "title": "DMIN",
        "search": "Search users/payments",
        "doSearch": "Find",
        "settings": "Settings & logout"
    }
}
</i18n>
<template>
    <MdLoadScreen :is-loading="doingSearch" :class="$style.wrapper">
        <div :class="$style.topbar">
            <span :class="$style.mobileOnly">
                <span :class="[fa.bars, $style.menuExpander]" @click="menuExpanded=!menuExpanded"></span>
            </span>
            <router-link :to="'/'" :class="[$style.mainTitle, $style.hideOnMobile]">
                FB<span :class="$style.symbol">{{ $t('titleSymbol') }}</span>{{ $t('title') }}
            </router-link>
            <span :class="$style.screenTitle">{{ screenTitle }}</span>
            <span :class="$style.strut"></span>
            <form :class="$style.search" @submit.stop.prevent="doSearch">
                <MdTextinput v-model="searchQuery" ref="searchInput" :class="$style.searchRecolor" :transparent="true">{{ $t('search') }}</MdTextinput>
                <button type="submit" :class="$style.searchButton"><span :class="fa.search"></span></button>
            </form>
            <span :class="$style.usermenu">
                <router-link :class="$style.usermenulink" :to="`/User/${user.Id}/Edit`">
                    <img :class="$style.avatar" :src="`https://www.gravatar.com/avatar/${emailMD5}?s=24`"/>
                </router-link>
                <router-link :class="[$style.hideOnMobile, $style.usermenulink]" :to="`/User/${user.Id}/Edit`">
                    <template v-if="user.FirstName || user.LastName">
                        {{ user.FirstName }}
                        {{ user.LastName }}
                    </template>
                    <template v-else>
                        {{ user.Email }}
                    </template>
                </router-link>
            </span>
            <span @click="showUserMenu" :class="[fa.cog, $style.settings]" :title="$t('settings')"></span>
        </div>
        <div :class="{[$style.menu]:true, [$style.menuExpanded]:menuExpanded}">
            <MainMenu/>
        </div>
        <div :class="{[$style.content]:true, [$style.contentWithMenuExpanded]:menuExpanded}">
            <router-view @pageTitleChanged="updateTitle"/>
        </div>

        <div :class="$style.overlay" style="z-index:1000000">
            <component v-for="overlay in overlays" :key="'overlay-'+overlay.id" :is="overlay.component" v-bind="overlay.props" v-on="overlay.on" @close="closeOverlay(overlay,$event)" :slotContent="overlay.slots" ref="overlayInstances"/>
            <!--portal-target name="overlays"></portal-target-->
        </div>
        <div :class="$style.overlay" ref="dialogTarget" style="z-index:1000001">
        </div>
        <div :class="$style.overlay" style="z-index:1000002">
            <transition-group :enter-class="$style.messageEnter" :enter-active-class="$style.messageEnterActive" :enter-to-class="$style.messageEnterTo"
                              :leave-class="$style.messageLeave" :leave-active-class="$style.messageLeaveActive" :leave-to-class="$style.messageLeaveTo"
            >
                <Message v-for="message in messages" :key="message.id" :message="message" @dismiss="hideMessage(message)"/>
            </transition-group>
        </div>
    </MdLoadScreen>
</template>
<style module>
@import '../css-mixins/vars.css';

:root {
    --topbar-color: #2a2a2a;
    --topbar-foreground: var(--material-background);
}

[data-theme="dark"] {
    --topbar-color: #600;
}

.symbol {
    color: red;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.overlay > * {
    pointer-events: auto;
}

.wrapper {
    composes: wrapper from './global.css';
    display: grid;
    grid-template-columns: 300px 5fr;
    grid-template-rows: 40px 1fr;
}

.topbar {
    grid-column: span 2;
    display: flex;
    flex-direction: row;
    background: var(--topbar-color);
    color: var(--topbar-foreground);
    align-items: center;
}

.mobileOnly {
    display: none;
}

.mainTitle {
    font-size: 150%;
    margin: -4px 10px 0 10px;
    flex: 0 0 280px;
    color: var(--topbar-foreground);
    text-decoration: none;
}

.mainTitle:active, .mainTitle:visited, .mainTitle:hover {
    color: var(--topbar-foreground);
    text-decoration: none;
}

.screenTitle {
    margin-left: 1em;
}

.strut {
    flex: 1 1 auto;
}

.search {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex: 0 1 25%;
}

.searchRecolor {
    color: var(--topbar-foreground) !important;
}

.searchRecolor input {
    color: var(--topbar-foreground) !important;
}

.searchButton {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: transparent;
    display: block;
    color: var(--topbar-foreground)
}

.usermenu {
    margin: 0 1em 0 4em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.usermenulink, .usermenulink:hover, .usermenulink:visited, .usermenulink:active {
    color: var(--topbar-foreground);
    text-decoration: none;
}

.settings {
    margin: 0 1em 0 .25em;
    font-size: 20px !important;
    cursor: pointer;
}

.avatar {
    border-radius: 20px;
    border: 2px solid var(--material-border);
    margin: 0 .5em 0 0;
}

.menu {
    background: var(--menu-background);
    overflow-y: auto;
}

.menuExpanded {
}

.contentWithMenuExpanded {
}

.content {
    position: relative;
    overflow-x: auto;
}

@media (max-width: 700px) {
    .menu {
        display: none;
    }

    .hideOnMobile {
        display: none;
    }

    .mobileOnly {
        display: block;
    }

    .wrapper {
        composes: wrapper from './global.css';
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 1fr;
    }

    .mainTitle {
        flex-basis: auto;
    }

    .menuExpanded {
        display: block;
    }

    .contentWithMenuExpanded {
        display: none !important;
    }

    .menuExpander {
        font-size: 150% !important;
        margin: 0 10px;
        cursor: pointer;
    }
}

.messageEnter {
    margin-top: -100%;
}

.messageEnterActive {
    transition: margin-top ease .33s;
}

.messageEnterTo {
    margin-top: 1em;
}

.messageLeave {
    margin-right: 1em;
}

.messageLeaveActive {
    transition: margin-right ease .33s;
}

.messageLeaveTo {
    margin-right: -100%;
}

</style>
<style module="fa" src="../css-mixins/fontawesome.css"/>
<script>
import MainMenu from '../vue_modules/Components/MainMenu';
import auth from './auth';
import MdTextinput from '../vue_modules/MdComponents/MdTextInput/MdTextInput';
import app from '../utils/appInstance';
import md5 from 'md5';
import Message from './Message';
import UserSettingsDialog from './UserSettingsDialog';
import ws from 'fb-utils/webservice';
import MdLoadScreen from '../vue_modules/MdComponents/MdLoadScreen/MdLoadScreen';

export default {
    components: {
        MdLoadScreen,
        Message,
        MdTextinput,
        MainMenu
    },
    data: function () {
        return {
            screenTitle: '',
            user: auth.instance.userInfo || {},
            searchQuery: '',
            messages: [],
            overlays: [],
            handleKeyDown: (ev) => this.onKeyDown(ev),
            menuExpanded: false,
            doingSearch: false
        };
    },
    computed: {
        emailMD5 () {
            return md5(this.user.Email.trim().toLowerCase());
        }
    },
    mounted () {
        window.AdminApp.dialogTarget = this.$refs.dialogTarget;
        document.addEventListener('keydown', this.handleKeyDown);
    },
    destroyed () {
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        onKeyDown (ev) {
            if ((ev.key === 's' || ev.key === 'f') && (ev.ctrlKey || ev.altKey)) {
                ev.preventDefault();
                this.$refs.searchInput.select();
            }
        },
        updateTitle (newTitle) {
            this.screenTitle = newTitle;
            document.title = newTitle + ' - FlippingBook Admin';
        },
        doSearch () {
            this.doingSearch = true;
            const q = this.searchQuery.trim();
            if ((Number(q).toString() === q && Number(q) >= 10000000) || (q.length > 1 && q[0].toLowerCase() === 's' && Number(q.substring(1)).toString() === q.substring(1) && Number(q.substring(1)) >= 10000000)) {
                this.doingSearch = false;
                app().router.push('/payment/search?query=Transaction%3D%3D"' + q.trim() + '"');
            } else {
                if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(q.trim().toLowerCase())) {
                    ws.callApi('generic', 'logon', 'SearchUsers', {
                        request: {
                            Offset: 0,
                            Count: 2,
                            Query: q.trim(),
                            EmailMatchType: 'Start'
                        }
                    }, {putAccessToken: 'request.Token'}).then(result => {
                        this.doingSearch = false;
                        if (result.Total === 1) {
                            app().router.push(`/User/${result.Items[0].Id}/Edit`);
                        } else {
                            throw new Error();
                        }
                    }).catch(() => {
                        this.doingSearch = false;
                        app().router.push('/User/Search?query=' + encodeURI(q.trim()).replace(/\+/g, '%2B'));
                    });
                } else {
                    this.doingSearch = false;
                    app().router.push('/User/Search?query=' + encodeURI(q.trim()).replace(/\+/g, '%2B'));
                }
            }
        },
        showUserMenu () {
            app().showDialog(UserSettingsDialog);
        },
        showMessage (message) {
            if (message.sharedId) {
                for (let i = this.messages.length - 1; i >= 0; i--) {
                    if (this.messages[i].sharedId === message.sharedId) {
                        this.messages.splice(i, 1);
                    }
                }
            }
            this.messages.splice(0, 0, message);
            if (message.autoClose) {
                setTimeout(() => this.hideMessage(message), message.autoClose);
            }
        },
        hideMessage (message) {
            this.messages.splice(this.messages.indexOf(message), 1);
        },
        addOverlay (overlay) {
            this.overlays.push(overlay);
        },
        removeOverlay (overlay) {
            const index = this.overlays.indexOf(overlay);
            if (index !== -1) {
                this.overlays.splice(index, 1);
            }
        },
        clearOverlays () {
            this.menuExpanded = false;
            this.overlays.splice(0, this.overlays.length);
        },
        closeOverlay (overlay, ev) {
            overlay.close(ev);
        },
        getOverlayVM (overlay) {
            const index = this.overlays.indexOf(overlay);
            if (index !== -1) {
                return this.$refs.overlayInstances[index];
            }
            return null;
        }
    }
};
</script>
