var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("notFound")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }