import Vue from 'vue';
import Router from 'vue-router';
import auth from '../auth';
import routes from '../routes';
import sd from 'fb-utils/serviceDiscovery';
import isLocal from 'is_local';

export default function (app) {
    Vue.use(Router);
    const router = new Router({mode: 'history', routes});
    router.afterEach(() => {
        if (app.Vue?.$refs.rootView?.clearOverlays) { app.Vue.$refs.rootView.clearOverlays(); }
    });
    router.beforeEach((to, from, next) => {
        window.ga && window.ga('send', 'pageview', {
            'page': to.fullPath
        });
        if (to.matched.some(route => route.meta.unauth)) {
            next();
        } else {
            auth.instance.loaded.then(() => {
                if (auth.instance.isAuthorized) {
                    next();
                } else if (auth.instance.noPermissions) {
                    next({path: '/access-denied', query: {nextUrl: to.fullPath}});
                } else {
                    sd.instance().GetApi('generic', 'logon-ui').then(u => {
                        window.location.href = u + '?t=both&r=core-admin' + (isLocal ? '-local' : '') + encodeURIComponent(to.fullPath);
                    });
                }
            });
        }
    });
    app.router = router;
    return router;
}
