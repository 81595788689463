<script>
export default {
    computed: {
        Title () {
            return this.__title || "(╯'□')╯︵ ┻━┻ > Use this.setTitle(newTitle) Luke";
        }
    },
    created () {
        this.$emit('pageTitleChanged', this.Title);
    },
    methods: {
        setTitle (val) {
            this.__title = val;
            this.$emit('pageTitleChanged', val);
        }
    }
};
</script>;
