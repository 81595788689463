import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'settings',
        'name': 'FboVersions',
        'icon': fa.database,
        'path': 'Settings/FBOnline/Versions',
        'handler': 'PlatformVersions',
        'index': 20,
        'requirePermissions': ['Admin.FBOnline.Versioning']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'FboHashBlacklist',
        'icon': fa.database,
        'path': 'Settings/FBOnline/PdfBlacklist',
        'handler': 'PdfHashBlacklist',
        'index': 30,
        'requirePermissions': ['Admin.FBOnline']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'SuspiciousFileDetectionRules',
        'icon': fa.database,
        'path': 'Settings/FBOnline/SuspiciousFileDetectionRules',
        'handler': 'SuspiciousFileDetectionRules',
        'index': 30,
        'requirePermissions': ['Admin.FBOnline']
    }
];
export {menu};
