import moment from 'moment';

export default (val, fmt, utc, relDays, invalidValue) => {
    if (!val) {
        return invalidValue || '';
    }
    const date = utc ? moment.utc(val).local() : moment(val);
    if (date.isValid()) {
        if (relDays && date.isAfter(moment().add(-relDays, 'days'))) {
            return date.fromNow();
        }
        return date.format(fmt || 'LL');
    }
    return invalidValue || val;
};
