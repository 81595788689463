<template>
    <div :class="wrapperClasses">
        <label :for="elementId" :class="$style.materialLabel" v-if="slotProvided">
            <slot/>
        </label>
        <i :class="[fa.asterisk, $style.RequiredMark]" v-if="required"></i>
        <input v-bind="inputAttributes" v-bind:value="value" ref="input" v-on="inputListeners">
        <small :class="$style.materialNote" v-if="descriptionProvided">{{description}}</small>
        <p v-if="!isValid" :class="$style.materialValidationError">{{validationErrorText}}</p>
    </div>
</template>
<style module="fa" src="../../../css-mixins/fontawesome.css"/>
<style module src="./MdTextInput.css"/>
<script>
import IdGen from 'vue_modules/utils/id_gen';
import Validation from '../Validation';

export default {
    name: 'md-textinput',
    mixins: [Validation],
    props: {
        description: {
            type: String,
            default: undefined
        },
        inputType: {
            type: String,
            default: 'text'
        },
        value: [String, Number],
        required: Boolean,
        transparent: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            focused: false
        };
    },
    computed: {
        elementId () {
            return this.inputAttributes.id;
        },
        descriptionProvided () {
            return (
                (this.description !== undefined || this.description !== null) &&
                this.description !== ''
            );
        },
        slotProvided () {
            return this.$slots.default;
        },
        inputAttributes () {
            const defaults = {
                id: IdGen(),
                autocomplete: 'off',
                type: this.inputType
            };
            const attrsOverride = {
                class: [this.$style.materialInput, {[this.$style.materialInputInvalid]: !this.isValid}]
            };
            if (this.transparent) { attrsOverride.style = 'background: transparent'; }
            return Object.assign({}, defaults, this.$attrs, attrsOverride);
        },
        inputListeners: function () {
            const vm = this;
            // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
            return Object.assign(
                {},
                // Мы добавляем все слушатели из родителя
                this.$listeners,
                // Затем мы можем добавить собственные слушатели или
                // перезаписать поведение некоторых существующих.
                {
                    // Это обеспечит, что будет работать v-model на компоненте
                    input: function (event) {
                        vm.$emit('input', event.target.value);
                    },
                    focus: function (event) {
                        return vm.toggleFocus(event);
                    },
                    blur: function (event) {
                        return vm.toggleFocus(event);
                    }
                }
            );
        },

        wrapperClasses () {
            return [
                this.$style.materialWrapper,
                this.hasContent() ? this.$style.materialWrapperNotEmpty : '',
                this.focused ? this.$style.materialWrapperActive : ''
            ];
        }
    },
    methods: {
        toggleFocus () {
            this.focused = !this.focused;
            this.$emit(this.focused ? 'focus' : 'blur', this);
        },
        hasContent () {
            return (
                this.value !== undefined && this.value !== null && this.value !== ''
            );
        },
        select () {
            this.$refs.input.select();
        }
    }
};
</script>
