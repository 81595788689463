<i18n src="./names.json"/>
<i18n src="locale/Misc.json"/>
<template>
    <div :class="$style.wrapper">
        <div :class="$style.categories">
            <div v-for="c in groupedItems" :key="c.category" :class="[$style.category, $style['category-'+c.category]]">
                <span v-if="c.category" href="#" :class="[$style.categoryHandle, {[$style.categoryHandleCollapsed]:!c.expanded}]" @click.stop.prevent="toggleCategory(c)">{{c.name}}</span>
                <CollapseTransition>
                    <div :class="$style.categoryItems" v-if="c.expanded || !c.category">
                        <router-link v-for="(i,index) in c.items" :key="c.category+index" v-ifperm="i.requirePermissions" :perms="i.requirePermissions" :to="{name: i.routeName, params:i.defaults}" active-class="" :class="[$style.item, $style.itemFlex, {[$style.currentItem]: i.isCurrent($route)}]" :exact-path="i.exactPath">
                            <span :class="[i.icon, $style.icon]"></span>
                            <span :class="$style.name">{{$te(i.name)?$t(i.name):i.name}}</span>
                        </router-link>
                    </div>
                </CollapseTransition>
            </div>
        </div>
    </div>
</template>
<style module>
@import '../../../css-mixins/colors.css';
@import '../../../css-mixins/vars.css';

:root {
    --menu-gradient: linear-gradient(90deg, rgba(240, 240, 240, 0), rgba(240, 240, 240, .6));
    --menu-handle-default-category: #b0ffb0;
    --menu-handle-content-category: #ffb0b0;
    --menu-handle-tools-category: #ffffb0;
    --menu-handle-products-category: #b0b0ff;
    --menu-handle-settings-category: #badf0d;
    --menu-handle-site-category: #b884d7;
}

[data-theme="dark"] {
    --menu-gradient: linear-gradient(90deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .6));
    --menu-handle-default-category: #2b3e2b;
    --menu-handle-content-category: #6f4c4c;
    --menu-handle-tools-category: #666648;
    --menu-handle-products-category: #41415e;
    --menu-handle-settings-category: #576a08;
    --menu-handle-site-category: #463252;
}

.wrapper {
    user-select: none;
}

.categories {
}

.category {
    padding-left: 5px;
}

.categoryHandle {
    display: block;
    padding: 3px 1em;
    background: var(--menu-gradient);
}

.categoryHandle:after {
    font-family: FontAwesome;
    transform: rotate(-90deg);
    transform-origin: 2px 10px;
    transition: transform ease .3s;
    filter: blur(0);
    content: '\f0da';
    float: right;
}

.categoryHandle.categoryHandleCollapsed:after {
    font-family: FontAwesome;
    transform: rotate(90deg);
    transition: transform ease .3s;
    filter: blur(0);
    content: '\f0da';
    float: right;
}

.category- {
    composes: category;
    background: var(--menu-handle-default-category);
}

.category-content {
    composes: category;
    background: var(--menu-handle-content-category);
}

.category-tools {
    composes: category;
    background: var(--menu-handle-tools-category);
}

.category-products {
    composes: category;
    background: var(--menu-handle-products-category);
}

.category-settings {
    composes: category;
    background: var(--menu-handle-settings-category);
}

.category-site {
    composes: category;
    background: var(--menu-handle-site-category);
}

.categoryItems {
    margin-left: 3px !important;
    background: var(--menu-background);
}

.item {
    font-size: 110%;
    border-bottom: 1px solid var(--menu-background-border);
}

a.item, a.item:hover, a.item:visited, a.item:active {
    color: var(--material-foreground);
    text-decoration: none;
}

.item {
    display: flex;
    padding: 0.45em .4em;
    transition: background-color ease .25s;
    position: relative;
}

.item:hover {
    background: var(--menu-background-hover);
}

.currentItem {
    background: var(--selection-background);
    color: var(--selection-foreground);
    position:relative;
}

a.currentItem, a.currentItem:hover, a.currentItem:visited, a.currentItem:active {
    background: var(--selection-background);
    color: var(--selection-foreground);
}

.itemFlex {
    display: flex;
    align-items: center;
}

.icon {
    flex: 0 0 1.5em;
    align-self: center;
    justify-self: center;
    text-align: center;
}

.name {
    flex: 1 1 100%;
}
</style>
<script>
import allManifests from 'core/allManifests';
import _ from 'underscore';
import storage from 'fb-utils/storage';
import CollapseTransition from '../../MdComponents/CollapseTransition/index';
import MenuItem from './MenuItem';
import ifperm from '../../../core/init/vue/directives/ifperm';
// import app from 'utils/appInstance';

const categoryOrder = [null, 'content', 'products', 'tools', 'settings', 'site'];
export default {
    components: {CollapseTransition},
    props: [],
    data: function () {
        return {groupedItems: {}, currentPath: '', handler: null};
    },
    directives: {
        ifperm
    },
    methods: {
        toggleCategory (c) {
            c.expanded = !c.expanded;
            storage.local.set('menu-' + c.category + '-expanded', c.expanded);
        },
        handleNavigation (target) {
            this.currentPath = target.path;
            this.$nextTick(() => {
                const elem = document.querySelector('.' + this.$style.currentItem);
                if (elem) {
                    if (elem.scrollIntoViewIfNeeded) {
                        elem.scrollIntoViewIfNeeded();
                    } else {
                        elem.scrollIntoView();
                    }
                }
            });
        }
    },
    destroyed () {
        // app().off('navigation', this.handler);
    },
    created () {
        // app().on('navigation', this.handler = target => this.handleNavigation(target));
        this.groupedItems = _.chain(allManifests)
            .map(mft => mft.menu.map(m => { m.moduleName = mft.moduleName; return m; }))
            .flatten()
            .filter(mi => mi.inMainMenu)
            .groupBy(function (mi) {
                return mi.category;
            })
            .map((items, category) => ({
                category: category === 'global' ? null : category,
                name: this.$t('Category' + category),
                expanded: category === 'generic' || storage.local.get('menu-' + category + '-expanded') === 'true',
                items: _.sortBy(items.map(i => new MenuItem(i)), i => i.index)
            }))
            .sortBy(c => categoryOrder.indexOf(c.category))
            .value();
    }
};
</script>
