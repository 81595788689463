export default (text, length, delimiters) => {
    if (!length || length < 0) { length = 10; }
    if (!delimiters) { delimiters = /\s+/; }
    const parts = text.split(delimiters);
    let rv = '…' + parts[parts.length - 1];
    let i = 0;
    while (rv.length + parts[i].length < length) { rv = parts[i++] + rv; }
    if (i === 0) {
        rv = text;
    }
    if (rv.length > length + 1) {
        rv = text.substring(0, length >> 1) + '…' + text.substring(text.length - (length >> 1));
    }
    return rv;
};
