import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'ProductManagement',
        'icon': fa.sitemap,
        'path': 'Products/:server?/:product?/:edition?/Edit',
        'handler': 'Products',
        'index': 30,
        'requirePermissions': ['Admin.Products.Edit'],
        'extraPaths': [/Products\/.*\/(Edit|Versions)/]
    },
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'DownloadDistros',
        'icon': fa.download,
        'path': 'Products/:productName/:editionName/Download',
        defaults: {productName: 'Publisher2', editionName: 'Trial'},
        'handler': 'Downloads',
        // 'extraPaths': ['Products/.*/Download'],
        'index': 35
    },
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'NotificationsX',
        'icon': fa['file-o'],
        'path': 'Notifications/:id?',
        'handler': 'Notifications',
        'index': 40,
        'requirePermissions': ['Admin.Notifications']
        // 'extraPaths': ['Notifications/.*']
    },
    {
        'inMainMenu': false,
        'path': 'Products/:serverName/:productName/:editionName/Versions',
        'handler': 'Versions'
    },
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'ExpirationNotifications',
        'icon': fa['file-o'],
        'index': 41,
        'requirePermissions': ['Admin.Notifications'],
        'path': 'Expirations/:id?',
        'handler': 'Expirations'
        // 'extraPaths': ['Expirations/.*', 'Expirations']
    }
];
export {menu};
