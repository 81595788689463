var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.name,
        "appear-active-class": "",
        "appear-class": "",
        "appear-to-class": "",
        "enter-active-class": "",
        "enter-class": "",
        "enter-to-class": "",
        "leave-active-class": "",
        "leave-class": "",
        "leave-to-class": "",
      },
      on: {
        enter: _vm.enter,
        "after-enter": _vm.afterEnter,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }