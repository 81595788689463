
module.exports = function (bytes, precision) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
    const terabyte = gigabyte * 1024;
    const longMax = 9223372036854775807;
    let sign = '';
    if (bytes < 0) {
        bytes = Math.abs(bytes);
        sign = '-';
    }
    if ((bytes >= 0) && (bytes < kilobyte)) {
        return sign + bytes + ' B';
    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
        return sign + (bytes / kilobyte).toFixed(precision) + ' KB';
    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
        return sign + (bytes / megabyte).toFixed(precision) + ' MB';
    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
        return sign + (bytes / gigabyte).toFixed(precision) + ' GB';
    } else if (bytes >= terabyte) {
        if (bytes >= longMax) {
            return 'unlimited';
        } else {
            return sign + (bytes / terabyte).toFixed(precision) + ' TB';
        }
    } else {
        return sign + bytes + ' B';
    }
};
