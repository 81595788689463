import misbehavior from '../misbehavior';
import mc from 'utils/modelCache';
import md5 from 'md5';
import audit from 'fb-utils/audit';
import {Promise} from 'rsvp';
import ws from 'fb-utils/webservice';
import sd from 'fb-utils/serviceDiscovery';
import moment from 'moment';
import _ from 'underscore';
import base64 from 'base64-js';
import utf8 from 'utf-8';
import auth from '../auth';
import enums from '../../utils/enums';
import Users from 'locale/Users.json';
import currentLocale from '../currentLocale';

const defaults = {
    ExtraEmails: [],
    Acceptance: [],
    Comments: [],
    ContactInfo: {},
    Created: null,
    CrmInfo: {},
    EmailMD5Hash: null,
    EnablePromotionalEmails: false,
    Errors: null,
    Id: null,
    Email: null,
    Password: null,
    ExternalTokens: [],
    FirstName: null,
    LastName: null,
    Groups: [],
    HasPassword: false,
    IsEnabled: false,
    IsReseller: false,
    LogonName: null,
    Modified: null,
    Permissions: [],
    Relations: [],
    UserData: [],
    AdminSettings: {},
    Security: {
        RequireMFA: false,
        DisableIPChecks: false,
        DisableDirectLogon: false,
        SAML2SSOOwner: false,
        SAML2SSORelated: false,
        DisableExternalLogon: false
    },
    PasswordHistory: [],
    PasswordPolicies: []
};

const loadersMap = {
    comments: 'basic',
    contact: 'basic',
    crm: 'basic',
    settings: 'basic',
    extraEmails: 'basic'
};
const relTypes = {
    'subuser': () => currentLocale.translate(Users, 'Subuser'),
    'superuser': () => currentLocale.translate(Users, 'Superuser'),
    'reseller': () => currentLocale.translate(Users, 'Reseller'),
    'client': () => currentLocale.translate(Users, 'Client'),
    'publisher': () => currentLocale.translate(Users, 'Publisher'),
    'reader': () => currentLocale.translate(Users, '.Reader'),
    'alias': () => currentLocale.translate(Users, 'Alias'),
    'canonical': () => currentLocale.translate(Users, 'Canonical'),
    'fbo-subuser': () => currentLocale.translate(Users, 'FboSubuser'),
    'fbo-superuser': () => currentLocale.translate(Users, 'FboSuperuser')
};

function commentParser (src) {
    try {
        if (src.Timestamp === 'Invalid date') {
            src.Timestamp = moment();
            src.TimestampString = '';
        } else {
            src.Timestamp = moment(src.Timestamp, moment.ISO_8601);
            if (src.Timestamp.isAfter(moment().add(-3, 'days'))) {
                src.TimestampString = src.Timestamp.fromNow();
            } else {
                src.TimestampString = src.Timestamp.format('L LT');
            }
        }
    } catch (e) {
        src.TimestampString = 'Bad date: ' + e;
    }
    if (!src.Changelog) { src.Changelog = [{Type: 'created', User: src.Author, Date: src.Timestamp}]; }
    if (!src.Version) { src.Version = 1; }
    if (src.IsPinned === undefined) { src.IsPinned = false; }
    if (src.IsDeleted === undefined) { src.IsDeleted = false; }
    return src;
}

const __eeClasses = {
    'Previous': 'info',
    'Admin': 'success'
};

function extraEmailParser (eml) {
    eml.Kind = eml.Type;
    eml.KindLocalized = currentLocale.translate(Users, 'ExtraEmailKind' + eml.Kind) || eml.Kind;
    eml.CreateTime = moment.utc(eml.CreateTime).local();
    eml.CreateTimeString = eml.CreateTime.format('LLL');
    eml.KindClass = __eeClasses[eml.Kind] || 'default';
    return eml;
}

function parser (that) {
    return function (src) {
        if (src.LockoutEnd) { src.LockoutEnd = moment.utc(src.LockoutEnd).local(); }
        if (src.Relations) {
            _.each(src.Relations, function (rel) {
                rel.CreatedDate = moment.utc(rel.CreatedDate).local();
                rel.CreatedDateString = rel.CreatedDate.format('LL');
                rel.TypeString = relTypes[rel.Type] ? relTypes[rel.Type]() : rel.Type;
            });
        }

        if (src.SecurityPolicy !== undefined) {
            const pol = enums.SSOSecurityPolicy.Parse(src.SecurityPolicy);
            that.Security.DisableIPChecks = pol.HasFlag('DisableIPChecks');
            that.Security.RequireMFA = pol.HasFlag('RequireMFA');
            that.Security.DisableDirectLogon = pol.HasFlag('DisableDirectLogon');
            that.Security.SAML2SSOOwner = pol.HasFlag('SAML2SSOOwner');
            that.Security.SAML2SSORelated = pol.HasFlag('SAML2SSORelated');
            that.Security.DisableExternalLogon = pol.HasFlag('DisableExternalLogon');
        }
        src.__shadowEmail = src.Email;
        src.IsReseller = (src.Groups && _.contains(src.Groups, 'Resellers'));
        let superuser = _.find(src.Relations, function (r) {
            return r.Type === 'superuser';
        });
        if (superuser) {
            src.Superuser = new UserInfo2({userInfo: superuser.Target});
        }
        superuser = _.find(src.Relations, function (r) {
            return r.Type === 'fbo-superuser';
        });
        if (superuser) {
            src.FboSuperuser = new UserInfo2({userInfo: superuser.Target});
        }
        superuser = _.find(src.Relations, function (r) {
            return r.Type === 'reseller';
        });
        if (superuser) {
            src.Reseller = new UserInfo2({userInfo: superuser.Target});
        }
        if (src.Created) {
            src.Created = moment.utc(src.Created).local();
            src.CreatedString = src.Created.format('LLLL');
        }
        if (src.Modified) {
            src.Modified = moment.utc(src.Modified).local();
            src.ModifiedString = src.Modified.format('LLLL');
        }
        if (src.Email) {
            src.EmailMD5Hash = md5(src.Email.trim().toLowerCase());
        }
        if (src.Acceptance) {
            src.Acceptance = _.chain(src.Acceptance).map(a => {
                a.AcceptedOn = moment.utc(a.AcceptedOn).local();
                a.AcceptedOnString = a.AcceptedOn.format('LL');
                a.AcceptedOnFullString = a.AcceptedOn.format('LLLL');
                return a;
            }).sortBy(x => x.AcceptedOn).value();
        }
        const contactInfo = _.find(src.UserData, d => d.Name === 'ContactInfo');

        if (contactInfo) {
            try {
                src.ContactInfo = JSON.parse(utf8.getStringFromBytes(base64.toByteArray(contactInfo.Value)));
            } catch (e) {
                src.ContactInfo = {};
            }
        } else {
            src.ContactInfo = {};
        }
        const adminSettings = _.find(src.UserData, d => d.Name === 'AdminSettings');
        if (adminSettings) {
            try {
                if (typeof adminSettings.Value === 'string') { adminSettings.Value = base64.toByteArray(adminSettings.Value); }
                src.AdminSettings = JSON.parse(utf8.getStringFromBytes(adminSettings.Value));
            } catch (e) {
                src.AdminSettings = {};
            }
        } else {
            src.AdminSettings = {};
        }
        const crmInfo = _.find(src.UserData, d => d.Name === 'CRMProfile');
        if (crmInfo) {
            try {
                src.CrmInfo = JSON.parse(utf8.getStringFromBytes(base64.toByteArray(crmInfo.Value)));
            } catch (e) {
                src.CrmInfo = {};
            }
        } else {
            src.CrmInfo = {};
        }
        const comments = _.find(src.UserData, d => d.Name === 'AdminComments');
        if (comments) {
            try {
                src.Comments = JSON.parse(utf8.getStringFromBytes(base64.toByteArray(comments.Value))).map(commentParser);
            } catch (e) {
                src.Comments = [];
            }
        } else {
            src.Comments = [];
        }

        const passwordHistory = _.find(src.UserData, d => d.Name === 'PasswordHistory');
        if (passwordHistory) {
            try {
                src.PasswordHistory = JSON.parse(utf8.getStringFromBytes(base64.toByteArray(passwordHistory.Value)));
            } catch (e) {
                src.PasswordHistory = [];
            }
        } else {
            src.PasswordHistory = [];
        }
        let i = 0;
        src.ExtraEmails = (src.ExtraEmails || []).map(eml => {
            eml.Index = i++;
            return extraEmailParser(eml);
        });
        Object.assign(that, src);
        that.__shadowExtraEmails = that.ExtraEmails.map(e => _.clone(e));
    };
}

const loaders = {
    basic: function (that) {
        if (!that.Id) {
            return Promise.resolve({});
        } else {
            const ud = [];
            ud.push('ContactInfo');
            ud.push('CRMProfile');
            ud.push('AdminComments');
            ud.push('AdminSettings');
            ud.push('PasswordHistory');
            return ws.callRestApiPost('generic',
                'logon2',
                'user',
                {
                    Subject: [{ Id: that.Id }],
                    RequiredUserInfo: {
                        IncludeUserData: ud.length ? ud : null,
                        IncludeUserRelations: ['subuser', 'superuser', 'client', 'reseller', 'reader', 'publisher', 'alias', 'canonical', 'fbo-subuser', 'fbo-superuser'],
                        IncludeAcceptance: true,
                        IncludeExtraEmails: true,
                        IncludePasswordPolicy: true
                    }
                },
                {
                    putAccessToken: null,
                    tokenInHeaders: true
                }
            ).then(data => {
                if (data.Users[0]) { parser(that)(data.Users[0]); } else { throw new Error('User loading failed'); }
            });
        }
    },
    status: function (that) {
        if (!that.Id) {
            that.IsEnabled = true;
            return Promise.resolve({});
        } else {
            return ws.callRestApiPost('generic', 'logon2', 'user/ban', { Id: that.Id }).then(function (data) {
                that.IsEnabled = data.IsEnabled;
            });
        }
    }
};

const savers = {
    basic: {
        order: 0,
        fn: function (that, auditEvent) {
            const rq = {
                Identity: that.Id ? {Id: that.Id} : null,
                NewFirstName: that.FirstName,
                NewLastName: that.LastName,
                NewEmail: that.Email,
                ClearLogonName: that.LogonName === '', // jshint ignore:line
                NewLogonName: that.LogonName,
                NewEnablePromotionalMails: that.EnablePromotionalMails,
                NewGroups: that.Groups,
                ParentAuditEvent: auditEvent,
                NewAnalyticsOptOut: that.AnalyticsOptOut,
                NewSecurityPolicy: (that.Security.DisableIPChecks ? 1 : 0) | (that.Security.RequireMFA ? 2 : 0)
            };
            if (that.Password) {
                rq.NewPassword = that.Password;
            }

            return ws.callRestApiPut('generic', 'logon2', 'user', rq)
                .then(function (result) {
                    if (!Number(that.Id) && result.Identity) {
                        that.Id = result.Identity.Id;
                    }
                    if (that.Password) {
                        that.PasswordHistory.push({InvalidationDate: moment()});
                    }
                    that.Modified = moment();
                    if (that.__shadowEmail !== that.Email && that.ExtraEmails) {
                        that.ExtraEmails.push({
                            KindLocalized: currentLocale.translate(Users, 'ExtraEmailKindPrevious'),
                            CreateTime: moment(),
                            CreateTimeString: moment().format('LLL'),
                            Kind: 'Previous',
                            KindClass: __eeClasses['Previous'],
                            Email: that.__shadowEmail
                        });
                    }
                    that.__shadowEmail = that.Email;
                    return result;
                });
        }
    },
    contact: {
        order: 0,
        fn: function (that, auditEvent) {
            if (!that.ContactInfo) {
                return Promise.resolve();
            }
            return ws.callRestApiPut('generic',
                'logon2',
                'user/data/ContactInfo',
                {
                    subject: {Id: that.Id},
                    dataName: 'ContactInfo',
                    data: base64.fromByteArray(utf8.setBytesFromString(JSON.stringify(that.ContactInfo))),
                    parentAuditEvent: auditEvent || ''
                },
                { tokenInHeaders: true, putAccessToken: null });
        }
    },
    crm: {
        order: 0,
        fn: function (that, auditEvent) {
            if (!that.CrmInfo) {
                return Promise.resolve();
            }
            return ws.callRestApiPut('generic',
                'logon2',
                'user/data/CRMProfile',
                {
                    subject: {Id: that.Id},
                    dataName: 'CRMProfile',
                    data: base64.fromByteArray(utf8.setBytesFromString(JSON.stringify(that.CrmInfo))),
                    parentAuditEvent: auditEvent || ''
                },
                { tokenInHeaders: true, putAccessToken: null });
        }
    },
    settings: {
        order: 0,
        fn: function (that, auditEvent) {
            if (!that.AdminSettings) {
                return Promise.resolve();
            }
            return ws.callApi('generic',
                'logon',
                'SetUserData2',
                {
                    identity: {Id: that.Id},
                    dataName: 'AdminSettings',
                    data: utf8.setBytesFromString(JSON.stringify(that.AdminSettings)),
                    parentAuditEvent: auditEvent || ''
                },
                {putAccessToken: 'accessToken'});
        }
    },
    comments: {
        order: 0,
        fn: function (that, auditEvent) {
            if (!that.Comments) {
                return Promise.resolve();
            }
            const models = _.map(that.Comments, function (c) {
                return _.omit(c, 'TimestampString');
            });

            return ws.callRestApiPut('generic',
                'logon2',
                'user/data/AdminComments',
                {
                    subject: {Id: that.Id},
                    dataName: 'AdminComments',
                    data: base64.fromByteArray(utf8.setBytesFromString(JSON.stringify(models))),
                    parentAuditEvent: auditEvent || ''
                },
                { tokenInHeaders: true, putAccessToken: null });
        }
    },
    status: {
        order: 1,
        fn: function (that, auditEvent) {
            return ws.callRestApiPut('generic', 'logon2', 'user/ban?ban=' + (that.IsEnabled ? 'false' : 'true'), { Id: that.Id, parentAuditEvent: auditEvent || '' });
        }
    },
    extraEmails: {
        order: 0,
        fn: function (that) {
            const oldMails = that.__shadowExtraEmails;
            const newMails = that.ExtraEmails;
            const removed = _.filter(oldMails, old => !_.find(newMails, n => n.Email === old.Email && n.Kind === old.Kind));
            const added = _.filter(newMails, old => !_.find(oldMails, n => n.Email === old.Email && n.Kind === old.Kind));
            let p = Promise.resolve();
            if (removed.length) {
                p = p.then(() => ws.callRestApiDelete('generic',
                    'logon2',
                    'user/extraemail',
                    {
                        Subject: { Id: that.Id },
                        Emails: _.map(removed, r => r.Email)
                    })
                );
            }
            if (added.length) {
                p = p.then(() =>
                    ws.callRestApiPost('generic',
                        'logon2',
                        'user/extraemail',
                        {
                            Subject: { Id: that.Id },
                            Emails: _.map(added, r => ({
                                Email: r.Email,
                                Comment: r.Comment,
                                Type: r.Kind
                            }))
                        })
                );
            }
            that.__shadowExtraEmails = _.map(that.ExtraEmails, e => _.clone(e));
            return p;
        }
    }
};

class UserInfo2 {
    constructor (userId) {
        Object.assign(this, defaults);
        this.Security = Object.assign({}, defaults.Security);
        if (typeof userId === 'object') {
            this.Id = userId.Id;
            parser(this)(userId);
            // Basic не загружен, так как он, скорее всего, неполноценный
            this.__partLoaders = {};
        } else {
            this.Id = userId;
            this.__partLoaders = {};
        }
    }

    load (parts) {
        if (typeof parts === 'string') {
            parts = Array.prototype.slice.call(arguments);
        }
        return Promise.all(parts.map(p => {
            const realPart = loadersMap[p] || p;
            return this.__partLoaders[realPart] || (this.__partLoaders[realPart] = loaders[realPart](this));
        }
        ));
    }

    removeRelation (relation) {
        return ws.callRestApiDelete('generic', 'logon2', 'user/relation', {
            Right: { Id: this.Id },
            Left: { Id: relation.Target.Id },
            Relation: relation.Type
        }).then(d => {
            const i = this.Relations.findIndex(x => x.Target.Id === relation.Target.Id && x.Type === relation.Type);
            if (i !== -1) { this.Relations.splice(i, 1); }
            return d;
        });
    }

    addRelation (relatedUserId, type) {
        return ws.callRestApiPut('generic', 'logon2', 'user/relation', {
            Left: {Identity: { Id: relatedUserId }},
            Right: {Identity: { Id: this.Id }},
            Relation: type
        }).then(d => {
            this.Relations.push({Target: {Id: relatedUserId}, Type: type, CreatedDate: moment()});
            return d;
        });
    }

    save () {
        const arg0 = arguments[0];
        let options = {};
        let offset = 0;
        if (_.isObject(arg0) && !_.isString(arg0)) {
            options = arg0;
            offset = 1;
        }
        let toSave = _.uniq(_.compact(_.flatten(Array.prototype.slice.call(arguments, offset))));
        if (!toSave.length) {
            toSave = Object.keys(this.__partLoaders);
        }

        return audit({Parent: options.ParentAuditEvent,
            RelatedUser: this.Id,
            Actions: [{
                Type: 'UpdateUser',
                ObjectServer: 'SSO',
                ObjectType: 'User',
                ObjectIdentity: this.Id
            }]})
            .then(auditEvent => {
                savers.status.order = this.IsEnabled ? -1 : 1;
                const s = _.chain(toSave).uniq().map(p => savers[p]).groupBy(x => x.order).map((v, k) => ({order: k, savers: v})).sortBy(x => x.order).value();
                let p = Promise.resolve();
                for (let i = 0; i < s.length; i++) {
                    const iter = s[i];
                    p = p.then(() => Promise.all(iter.savers.map(x => x.fn(this, auditEvent))));
                }
                return p;
            });
    }

    reload () {
        const parts = Object.keys(this.__partLoaders);
        this.__partLoaders = {};
        return this.load(parts);
    }

    addComment (text, auditEvent, isNotMisbehavior) {
        if (!text) {
            return Promise.resolve();
        }
        return this.load('comments').then(() => {
            const newComment = {
                Author: auth.instance.userInfo.Id,
                Timestamp: moment(),
                Text: text
            };

            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };
            newComment.Text = (newComment.Text || '').replace(/[&<>"']/g, m => map[m]).replace('\n', '<br/>', 'g');
            this.Comments.push(commentParser(newComment));
            if (!isNotMisbehavior) {
                misbehavior(`Комментарий к пользователю <https://admin.flippingbook.com/User/${this.Id}/Payments|SSOID#${this.Id} ${this.Email}>: ${newComment.Text}`);
            }
            mc.forget('UserInfo-' + this.Id);
            return this.save({ParentAuditEvent: auditEvent}, 'comments');
        });
    }

    addComment2 (text, isPinned, auditEvent, isNotMisbehavior) {
        if (!text) {
            return Promise.resolve();
        }
        return this.load('comments').then(() => {
            const newComment = {
                Author: auth.instance.userInfo.Id,
                Timestamp: moment(),
                Text: text,
                Version: 2,
                IsPinned: isPinned,
                IsDeleted: false,
                Changelog: [{Type: 'created', User: auth.instance.userInfo.Id, Date: moment()}]
            };

            this.Comments.push(commentParser(newComment));
            if (!isNotMisbehavior) {
                misbehavior(`Комментарий к пользователю <https://admin.flippingbook.com/User/${this.Id}/Payments|SSOID#${this.Id} ${this.Email}>: ${newComment.Text}`);
            }
            mc.forget('UserInfo-' + this.Id);
            return this.save({ParentAuditEvent: auditEvent}, 'comments');
        });
    }

    restorePassword (mode, delivery) {
        return sd.instance().GetApiRaw('generic', 'logon2').then(url => {
            const authority = 'https://' + (url[0].ThinClientHost || url[0].Host) + '/';
            return ws.callRestApiPost('generic',
                'logon2',
                'user/restore-password',
                {
                    Mode: mode,
                    Delivery: delivery,
                    Identity: {
                        Id: this.Id
                    },
                    CancelUrl: authority + 'restore-password-cancelled?r=site',
                    FailedUrl: authority + 'restore-password-failed?r=site',
                    LoginUrl: authority + 'login?r=site',
                    ReturnUrl: authority + 'restore-password-update?r=site'
                }).then(data => data.ContinueUrl);
        });
    }

    detachPolicy (p) {
        return ws.callRestApiDelete('generic',
            'logon2',
            'password-policy/binding',
            {
                Policy: p.Id,
                User: p.SourceUserId,
                Relation: p.Relation
            }).then(() => this.reload());
    }
    attachPolicy (policy, relation) {
        return ws.callRestApiPut('generic',
            'logon2',
            'password-policy/binding',
            {
                Policy: policy,
                User: this.Id,
                Relation: relation
            }).then(() => this.reload());
    }
    unlock () {
        return ws.callRestApiPost('generic',
            'logon2',
            'user/unlock',
            {
                Id: this.Id
            }).then(() => this.reload());
    }
}

export default UserInfo2;
