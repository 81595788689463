const modelCache = {};
module.exports = {
    get: function (key, ctr) {
        const opts = Array.prototype.slice.call(arguments, 2);
        const CtrStub = function () {
            return ctr.apply(this, opts);
        };
        CtrStub.prototype = ctr.prototype;
        if (!key) {
            return new CtrStub();
        }
        if (!modelCache[key]) {
            modelCache[key] = new CtrStub();
        }
        return modelCache[key];
    },
    has: function (key) {
        return modelCache[key];
    },
    forget: function (key) {
        modelCache[key] = undefined;
    }
};
