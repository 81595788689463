<template>
    <span :class="$style.animated">
        <span :class="$style.face">(<span :class="$style.hand">╯</span>'□')<span :class="$style.hand">╯</span></span>
        <span :class="$style.table">┻━┻</span>
        <span v-for="t in 5" :key="'gear-'+t" :class="$style.gear" :style="{left:1.5*t+'em', 'animation-delay':-Math.random()*500+'ms'}">+</span>
        <span v-for="t in 20" :key="'conveyor-'+t" :class="$style.conveyor" :style="{'animation-delay':-t*100+'ms'}">-</span>
    </span>
</template>

<script>
export default {};
</script>

<style module>
    :root{
        --animation-time: 2s;
    }

    .animated {
        position: relative;
        font-size: 18px;
        padding-right: 12em;
    }

    .face {
        animation: faceBlush var(--animation-time) infinite;
    }

    .hand {
        animation: handThrow var(--animation-time) infinite;
        display: inline-block;
        transform-origin: left center;
    }

    .table {
        animation: tableFly var(--animation-time) infinite;
        display: inline-block;
        transform-origin: center center;
    }

    .conveyor {
        animation: conveyorCycle linear var(--animation-time) infinite;
        display: inline-block;
        transform-origin: center center;
        position: absolute;
        top: -14px;
        left: 100px;
    }

    .gear {
        animation: gearCycle linear var(--animation-time) infinite;
        display: inline-block;
        transform-origin: center center;
        position: absolute;
        top: -10px;
        margin-left: 68px;
        font-size: 150%;
    }

    @keyframes faceBlush{
        from {
            color:var(--default-foreground);
        }
        15% {
            color:red;
        }
        to {
            color:var(--default-foreground);
        }
    }

    @keyframes handThrow {
        from {
            transform: rotate(0);
        }
        13% {
            transform: rotate(0);
        }
        15% {
            transform: rotate(-30deg);
        }
        50% {
            transform: rotate(0);
        }
        to {
            transform: rotate(0);
        }
    }

    @keyframes tableFly {
        from {
            transform: translate(0, 0) rotate(180deg);
        }
        13% {
            transform: translate(0, 0) rotate(180deg);
        }
        20% {
            transform: translate(5em, -5em) rotate(450deg);
        }
        40% {
            transform: translate(10em, -1em) rotate(720deg);
        }
        90% {
            transform: translate(2em, -1em) rotate(720deg);
        }
        to {
            transform: translate(0, 0) rotate(540deg);
        }
    }

    @keyframes conveyorCycle {
        from {
            transform: translate(.5em, 0) rotate(0);
        }
        5% {
            transform: translate(0, .5em) rotate(-90deg);
        }
        10% {
            transform: translate(.5em, 1em) rotate(-180deg);
        }
        50% {
            transform: translate(10em, 1em) rotate(-180deg);
        }
        55% {
            transform: translate(10.5em, .5em) rotate(-270deg);
        }
        60% {
            transform: translate(10em, 0) rotate(-360deg);
        }
        to {
            transform: translate(.5em, 0) rotate(-360deg);
        }
    }

    @keyframes gearCycle {
        to {
            transform: rotate(0);
        }
        to {
            transform: rotate(-1440deg);
        }
    }
</style>
