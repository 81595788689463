import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'ServiceRegistry',
        'icon': fa.anchor,
        'path': 'Registry',
        'handler': 'Registry',
        'index': 300,
        'requirePermissions': ['Admin']
    }
];
export {menu};
