import Currency from 'fb-utils/currency';

//<script type="text/javascript">
    var Currencies = {};
    var exponent;
        
    exponent = [];
        exponent.push(3);
    Currencies['EUR'] = new Currency('€~', '-€~', 0, '.', exponent, '', false, 0.876)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['RUB'] = new Currency('~ р.', '-~ р.', 0, ',', exponent, ' ', false, 65)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['GBP'] = new Currency('£~', '-£~', 0, '.', exponent, ',', false, 0.777)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['AUD'] = new Currency('~ AU$', '-~ AU$', 0, '.', exponent, ',', false, 1.368)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['CAD'] = new Currency('~ CA$', '-~ CA$', 0, '.', exponent, ',', false, 1.317)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['CHF'] = new Currency('~ CHF', '-~ CHF', 0, ',', exponent, ',', false, 0.89)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['JPY'] = new Currency('~ ¥', '-~ ¥', 0, '.', exponent, ',', false, 112.7)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['HKD'] = new Currency('~ HK$', '-~ HK$', 0, '.', exponent, ',', false, 7.832)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['BRL'] = new Currency('R$ ~', '-~ R$', 0, '.', exponent, ',', false, 3.743)
    
        
        
    exponent = [];
        exponent.push(4);
        exponent.push(3);
    Currencies['CNY'] = new Currency('¥~', '-~ ¥', 0, '.', exponent, ',', false, 6.944)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['SEK'] = new Currency('~ kr', '-~ kr', 0, ',', exponent, ',', false, 9.005)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['DKK'] = new Currency('~ kr', '-~ kr', 0, ',', exponent, ',', false, 6.535)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['CZK'] = new Currency('~ Kč', '-~ Kč', 0, ',', exponent, ',', false, 22.767)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['TRY'] = new Currency('~ TL', '-~ TL', 0, '.', exponent, ',', false, 5.327)
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['USD'] = new Currency('$~', '($~)', 0, '.', exponent, ',', false, 1)
    
        
//</script>

export default Currencies;