var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("div", { class: _vm.$style.inner }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("NoAccess")))]),
      _vm._v(" "),
      _c("p", [
        _c(
          "a",
          { attrs: { href: _vm.logout }, on: { click: _vm.localLogout } },
          [_vm._v(_vm._s(_vm.$t("Logout")))]
        ),
        _vm._v(" " + _vm._s(_vm.$t("Or")) + " "),
        _c("a", { attrs: { href: "https://flippingbook.com" } }, [
          _vm._v(_vm._s(_vm.$t("ToSite"))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }