import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'site',
        'name': 'SiteBlacklist',
        'icon': fa['balance-scale'],
        'path': 'Site-Settings/Blacklist',
        'handler': 'Blacklist',
        'index': 11,
        'requirePermissions': ['Admin.Site.Blacklist']
    }
];
export {menu};
