var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _vm.slotProvided
      ? _c(
          "label",
          { class: _vm.$style.materialLabel, attrs: { for: _vm.elementId } },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.required
      ? _c("i", { class: [_vm.fa.asterisk, _vm.$style.RequiredMark] })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "input",
      _vm._g(
        _vm._b(
          { ref: "input", domProps: { value: _vm.value } },
          "input",
          _vm.inputAttributes,
          false
        ),
        _vm.inputListeners
      )
    ),
    _vm._v(" "),
    _vm.descriptionProvided
      ? _c("small", { class: _vm.$style.materialNote }, [
          _vm._v(_vm._s(_vm.description)),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isValid
      ? _c("p", { class: _vm.$style.materialValidationError }, [
          _vm._v(_vm._s(_vm.validationErrorText)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }