import moment from 'moment/moment';

export default {
    get: function () {
        return window.Locale;
    },
    set: function (locale) {
        window.Locale = locale;
        window.AdminApp.Loc = {};
        moment.locale(locale);
    },
    translate (dict, key) {
        const lang = (dict[window.Locale] || dict['ru'] || {});
        const keyParts = key.split('.');
        let o;
        for (let i = 0, o = lang; i < keyParts.length; i++) {
            o = o[keyParts[i]] || {};
        }
        return typeof (o) === 'string' ? o : key;
    }
};
