var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _vm.$slots.default
      ? _c(
          "label",
          { class: _vm.$style.materialLabel, attrs: { for: _vm.elementId } },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      _vm._g(
        _vm._b(
          {
            ref: "select",
            attrs: { disabled: _vm.disabled },
            domProps: { value: _vm.selectedValue },
          },
          "select",
          _vm.inputAttributes,
          false
        ),
        _vm.inputListeners
      ),
      _vm._l(_vm.options, function (option, optionVal) {
        return _c(
          "option",
          { key: optionVal, domProps: { value: optionVal } },
          [_vm._v(_vm._s(_vm.isComplexKey ? option.text : option))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.descriptionProvided
      ? _c("small", { class: _vm.$style.materialNote }, [
          _vm._v(_vm._s(_vm.description)),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isValid
      ? _c("p", { class: _vm.$style.materialValidationError }, [
          _vm._v(_vm._s(_vm.validationErrorText)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }