<i18n>
{
    "ru": {
        "Copy": "Копировать"
    },
    "en": {
        "Copy": "Copy"
    }
}
</i18n><template>
    <div :class="[$style.message, $style['tint-'+message.style]]">
        <button v-if="message.close" type="button" :class="$style.close" @click="$emit('dismiss')">&times;</button>
        <span v-if="message.icon" :class="[$style.icon, fa[message.icon]]"></span>
        <h4 v-if="message.header">{{message.header}}</h4>
        <p v-html="message.text"></p>
        <input v-if="message.copyText" :class="$style.text" type="text" readonly="readonly" :value="message.copyText" ref="textInput" @focus="$refs.textInput.select()"/>

        <p v-if="(message.buttons && message.buttons.length) || message.copyText">
            <button v-for="(button, index) in message.buttons" :key="message.id+'-'+index" :class="[buttons.medium, buttons.gray]" @click="button.handler()">{{button.text}}</button>
            <button v-if="message.copyText" :class="[buttons.medium, buttons.blue]" @click="doCopy">{{$t('Copy')}}</button>
        </p>
    </div>
</template>
<script>
export default {
    props: ['message'],
    methods: {
        doCopy () {
            this.$refs.textInput.select();
            document.execCommand('copy');
        }
    }
};
</script>
<style module="fa" src="../css-mixins/fontawesome.css"/>
<style module="buttons" src="../css-mixins/buttons.css"/>
<style module>
.message {
    composes: card from '../css-mixins/cards.css';
    clear: right;
    float: right;
    width: 25%;
    margin: 1em;
    padding: 1em;
    overflow: hidden;
}

.tint-info{
    background: var(--row-highlight-blue);
}
.tint-danger{
    background: var(--row-highlight-red);
}
.tint-warning{
    background: var(--row-highlight-orange);
}
.tint-default{
}

.text{
    background: var(--material-control-background);
    color: var(--material-foreground);
    border: 1px solid var(--material-border);
    width: 100%;
    margin: 1em 0;
}
.close{
    float: right;
    background: none;
    border: none;
    padding: .5em;
    display: block;
    color: var(--material-foreground);
}
</style>
