module.exports={"Enums":[{"FullName":"Mediaparts.Payments.ProcessorFrontend.Models.PaymentProcessor","Name":"PaymentProcessor","IsFlags":false,"Values":[{"Description":"Cleverbridge","Value":"Cleverbridge","UnderlayingValue":0}]},
{"FullName":"MaxMind.Db.ObjectType","Name":"ObjectType","IsFlags":false,"Values":[{"Description":"Extended","Value":"Extended","UnderlayingValue":0},{"Description":"Pointer","Value":"Pointer","UnderlayingValue":1},{"Description":"Utf8String","Value":"Utf8String","UnderlayingValue":2},{"Description":"Double","Value":"Double","UnderlayingValue":3},{"Description":"Bytes","Value":"Bytes","UnderlayingValue":4},{"Description":"Uint16","Value":"Uint16","UnderlayingValue":5},{"Description":"Uint32","Value":"Uint32","UnderlayingValue":6},{"Description":"Map","Value":"Map","UnderlayingValue":7},{"Description":"Int32","Value":"Int32","UnderlayingValue":8},{"Description":"Uint64","Value":"Uint64","UnderlayingValue":9},{"Description":"Uint128","Value":"Uint128","UnderlayingValue":10},{"Description":"Array","Value":"Array","UnderlayingValue":11},{"Description":"Container","Value":"Container","UnderlayingValue":12},{"Description":"EndMarker","Value":"EndMarker","UnderlayingValue":13},{"Description":"Boolean","Value":"Boolean","UnderlayingValue":14},{"Description":"Float","Value":"Float","UnderlayingValue":15}]},
{"FullName":"MaxMind.Db.FileAccessMode","Name":"FileAccessMode","IsFlags":false,"Values":[{"Description":"MemoryMapped","Value":"MemoryMapped","UnderlayingValue":0},{"Description":"Memory","Value":"Memory","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Portal.Core.Utility.RetryExceptionType","Name":"RetryExceptionType","IsFlags":false,"Values":[{"Description":"Retriable","Value":"Retriable","UnderlayingValue":0},{"Description":"Ignorable","Value":"Ignorable","UnderlayingValue":1},{"Description":"Unrecoverable","Value":"Unrecoverable","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Utility.NodeType","Name":"NodeType","IsFlags":false,"Values":[{"Description":"Root","Value":"Root","UnderlayingValue":0},{"Description":"Internal","Value":"Internal","UnderlayingValue":1},{"Description":"Leaf","Value":"Leaf","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Domain.Validation.WellKnownValidationErrors","Name":"WellKnownValidationErrors","IsFlags":false,"Values":[{"Description":"ValueMandatory","Value":"ValueMandatory","UnderlayingValue":0},{"Description":"ValueTooShort","Value":"ValueTooShort","UnderlayingValue":1},{"Description":"ValueTooLong","Value":"ValueTooLong","UnderlayingValue":2},{"Description":"ValueNotUnique","Value":"ValueNotUnique","UnderlayingValue":3},{"Description":"ValueExceedsMinimum","Value":"ValueExceedsMinimum","UnderlayingValue":4},{"Description":"ValueExceedsMaximum","Value":"ValueExceedsMaximum","UnderlayingValue":5},{"Description":"ValueWrongData","Value":"ValueWrongData","UnderlayingValue":6}]},
{"FullName":"Mediaparts.Portal.Core.RemoteAppenderModels.LoggingAction","Name":"LoggingAction","IsFlags":false,"Values":[{"Description":"Store","Value":"Store","UnderlayingValue":0},{"Description":"Transmit","Value":"Transmit","UnderlayingValue":1},{"Description":"Discard","Value":"Discard","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.CMS.CMSController+RedirectReason","Name":"RedirectReason","IsFlags":false,"Values":[{"Description":"Default","Value":"Default","UnderlayingValue":0},{"Description":"Canonicalization","Value":"Canonicalization","UnderlayingValue":1},{"Description":"Alias","Value":"Alias","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Payments.Processor.Period","Name":"Period","IsFlags":false,"Values":[{"Description":"All","Value":"All","UnderlayingValue":0},{"Description":"PreviousQuarter","Value":"PreviousQuarter","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.PriceType","Name":"PriceType","IsFlags":true,"Values":[{"Description":"None","Value":"None","UnderlayingValue":0},{"Description":"Immediate","Value":"Immediate","UnderlayingValue":1},{"Description":"Recurring","Value":"Recurring","UnderlayingValue":2},{"Description":"RecurringFirstInterval","Value":"RecurringFirstInterval","UnderlayingValue":4},{"Description":"All","Value":"All","UnderlayingValue":7}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.RollbackType","Name":"RollbackType","IsFlags":false,"Values":[{"Description":"DeleteInstance","Value":"DeleteInstance","UnderlayingValue":0},{"Description":"RollbackAttributes","Value":"RollbackAttributes","UnderlayingValue":1},{"Description":"DeleteExtraResource","Value":"DeleteExtraResource","UnderlayingValue":2},{"Description":"DeleteResellerResource","Value":"DeleteResellerResource","UnderlayingValue":3}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.DiscountType","Name":"DiscountType","IsFlags":false,"Values":[{"Description":"Reseller","Value":"Reseller","UnderlayingValue":20},{"Description":"Volume","Value":"Volume","UnderlayingValue":30},{"Description":"Personal","Value":"Personal","UnderlayingValue":25},{"Description":"Seasonal","Value":"Seasonal","UnderlayingValue":100},{"Description":"ExtraFee","Value":"ExtraFee","UnderlayingValue":10}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.NotificationKind","Name":"NotificationKind","IsFlags":false,"Values":[{"Description":"OneShotBuy","Value":"OneShotBuy","UnderlayingValue":0},{"Description":"SubscriptionTick","Value":"SubscriptionTick","UnderlayingValue":1},{"Description":"SubscriptionCancel","Value":"SubscriptionCancel","UnderlayingValue":2},{"Description":"SubscriptionHold","Value":"SubscriptionHold","UnderlayingValue":3}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.InvalidationReasonKind","Name":"InvalidationReasonKind","IsFlags":false,"Values":[{"Description":"TooLateForUpgradeOnly","Value":"TooLateForUpgradeOnly","UnderlayingValue":0},{"Description":"NoStateTransitionsGenerated","Value":"NoStateTransitionsGenerated","UnderlayingValue":1},{"Description":"PriceTooLow","Value":"PriceTooLow","UnderlayingValue":2},{"Description":"AmountTooBig","Value":"AmountTooBig","UnderlayingValue":3},{"Description":"InvalidTransition","Value":"InvalidTransition","UnderlayingValue":4},{"Description":"InvalidConfiguration","Value":"InvalidConfiguration","UnderlayingValue":5},{"Description":"PaymentPending","Value":"PaymentPending","UnderlayingValue":6}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.Method","Name":"Method","IsFlags":false,"Values":[{"Description":"Checkout","Value":"Checkout","UnderlayingValue":0},{"Description":"GetQuote","Value":"GetQuote","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.Data.PaymentStatus","Name":"PaymentStatus","IsFlags":false,"Values":[{"Description":"Unknown","Value":"Unknown","UnderlayingValue":0},{"Description":"Created","Value":"Created","UnderlayingValue":1},{"Description":"Cleared","Value":"Cleared","UnderlayingValue":2},{"Description":"Completed","Value":"Completed","UnderlayingValue":3},{"Description":"Checking","Value":"Checking","UnderlayingValue":4},{"Description":"Incomplete","Value":"Incomplete","UnderlayingValue":5},{"Description":"Fraudlent","Value":"Fraudlent","UnderlayingValue":6},{"Description":"Cancelled","Value":"Cancelled","UnderlayingValue":7},{"Description":"Refunded","Value":"Refunded","UnderlayingValue":8},{"Description":"Error","Value":"Error","UnderlayingValue":9},{"Description":"All","Value":"All","UnderlayingValue":10},{"Description":"SubscriptionActive","Value":"SubscriptionActive","UnderlayingValue":11},{"Description":"SubscriptionOnHold","Value":"SubscriptionOnHold","UnderlayingValue":12},{"Description":"SubscriptionCancelled","Value":"SubscriptionCancelled","UnderlayingValue":13},{"Description":"FulfilledIncomplete","Value":"FulfilledIncomplete","UnderlayingValue":14},{"Description":"Pending","Value":"Pending","UnderlayingValue":15}]},
{"FullName":"Mediaparts.Payments.Processor.Domain.Configuration.TransitionKind","Name":"TransitionKind","IsFlags":false,"Values":[{"Description":"Direct","Value":"Direct","UnderlayingValue":0},{"Description":"Assignment","Value":"Assignment","UnderlayingValue":1},{"Description":"ResellerAssignment","Value":"ResellerAssignment","UnderlayingValue":2},{"Description":"ResellerMediation","Value":"ResellerMediation","UnderlayingValue":3}]},
{"FullName":"FB.Utils.Query.Web.ReportStatus","Name":"ReportStatus","IsFlags":false,"Values":[{"Description":"Ordered","Value":"Ordered","UnderlayingValue":0},{"Description":"Running","Value":"Running","UnderlayingValue":1},{"Description":"Completed","Value":"Completed","UnderlayingValue":2},{"Description":"Error","Value":"Error","UnderlayingValue":3}]},
{"FullName":"Mediaparts.Licensing.Interface.Payments.Processor.TransitionType","Name":"TransitionType","IsFlags":true,"Values":[{"Description":"Initial","Value":"Initial","UnderlayingValue":1},{"Description":"Renewal","Value":"Renewal","UnderlayingValue":2},{"Description":"Upgrade","Value":"Upgrade","UnderlayingValue":4},{"Description":"Subscription","Value":"Subscription","UnderlayingValue":8},{"Description":"Cancellation","Value":"Cancellation","UnderlayingValue":16},{"Description":"PeriodSwitch","Value":"PeriodSwitch","UnderlayingValue":1024},{"Description":"Monthly","Value":"Monthly","UnderlayingValue":32},{"Description":"Yearly","Value":"Yearly","UnderlayingValue":64},{"Description":"Daily","Value":"Daily","UnderlayingValue":128},{"Description":"Weekly","Value":"Weekly","UnderlayingValue":256},{"Description":"Quarterly","Value":"Quarterly","UnderlayingValue":512},{"Description":"PeriodMask","Value":"PeriodMask","UnderlayingValue":992}]}],"ScanTime":"0:00:00","GenTime":"0:00:00.0012471"};