import currentLocale from '../core/currentLocale';
import productNames from '../locale/ProductNames.json';

module.exports = function (name) {
    if (!name) {
        return null;
    }

    return currentLocale.translate(productNames, (name && ('qwertyuiopasdfghjklzxcvbnm'.indexOf(name[0].toLowerCase()) !== -1)) ? name.replace('/', '_') : ('_' + name.replace('/', '_'))) || name;
};
