var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c(
      "div",
      { class: _vm.$style.categories },
      _vm._l(_vm.groupedItems, function (c) {
        return _c(
          "div",
          {
            key: c.category,
            class: [_vm.$style.category, _vm.$style["category-" + c.category]],
          },
          [
            c.category
              ? _c(
                  "span",
                  {
                    class: [
                      _vm.$style.categoryHandle,
                      { [_vm.$style.categoryHandleCollapsed]: !c.expanded },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.toggleCategory(c)
                      },
                    },
                  },
                  [_vm._v(_vm._s(c.name))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("CollapseTransition", [
              c.expanded || !c.category
                ? _c(
                    "div",
                    { class: _vm.$style.categoryItems },
                    _vm._l(c.items, function (i, index) {
                      return _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "ifperm",
                              rawName: "v-ifperm",
                              value: i.requirePermissions,
                              expression: "i.requirePermissions",
                            },
                          ],
                          key: c.category + index,
                          class: [
                            _vm.$style.item,
                            _vm.$style.itemFlex,
                            {
                              [_vm.$style.currentItem]: i.isCurrent(_vm.$route),
                            },
                          ],
                          attrs: {
                            perms: i.requirePermissions,
                            to: { name: i.routeName, params: i.defaults },
                            "active-class": "",
                            "exact-path": i.exactPath,
                          },
                        },
                        [
                          _c("span", { class: [i.icon, _vm.$style.icon] }),
                          _vm._v(" "),
                          _c("span", { class: _vm.$style.name }, [
                            _vm._v(
                              _vm._s(_vm.$te(i.name) ? _vm.$t(i.name) : i.name)
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }