var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.load, style: { background: _vm.bgColor } },
    [
      _c("gear", {
        class: [_vm.$style.gear, _vm.$style.one],
        attrs: { fill: "#94DDFF" },
      }),
      _vm._v(" "),
      _c("gear", {
        class: [_vm.$style.gear, _vm.$style.two],
        attrs: { fill: "#FB8BB9" },
      }),
      _vm._v(" "),
      _c("gear", {
        class: [_vm.$style.gear, _vm.$style.three],
        attrs: { fill: "#FFCD5C" },
      }),
      _vm._v(" "),
      _c("div", { class: _vm.$style.lilCircle }),
      _vm._v(" "),
      _c("svg", { class: _vm.$style.blurCircle }, [
        _c(
          "filter",
          { attrs: { id: "md-loadscreen-blur" } },
          [
            _c("fegaussianblur", {
              attrs: { in: "SourceGraphic", stdDeviation: "13" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "70",
            cy: "70",
            r: "66",
            fill: "transparent",
            stroke: _vm.bgColor,
            "stroke-width": "40",
            filter: "#md-loadscreen-blur",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }