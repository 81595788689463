import { render, staticRenderFns } from "./LoggedInLayout.vue?vue&type=template&id=ed7dee62&"
import script from "./LoggedInLayout.vue?vue&type=script&lang=js&"
export * from "./LoggedInLayout.vue?vue&type=script&lang=js&"
import style0 from "./LoggedInLayout.vue?vue&type=style&index=0&id=ed7dee62&prod&module=true&lang=css&"
import style1 from "../css-mixins/fontawesome.css?vue&type=style&index=1&prod&module=fa&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)
this["fa"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./LoggedInLayout.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports