var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.$style.message, _vm.$style["tint-" + _vm.message.style]] },
    [
      _vm.message.close
        ? _c(
            "button",
            {
              class: _vm.$style.close,
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("dismiss")
                },
              },
            },
            [_vm._v("×")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.message.icon
        ? _c("span", { class: [_vm.$style.icon, _vm.fa[_vm.message.icon]] })
        : _vm._e(),
      _vm._v(" "),
      _vm.message.header
        ? _c("h4", [_vm._v(_vm._s(_vm.message.header))])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.message.text) } }),
      _vm._v(" "),
      _vm.message.copyText
        ? _c("input", {
            ref: "textInput",
            class: _vm.$style.text,
            attrs: { type: "text", readonly: "readonly" },
            domProps: { value: _vm.message.copyText },
            on: {
              focus: function ($event) {
                return _vm.$refs.textInput.select()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      (_vm.message.buttons && _vm.message.buttons.length) ||
      _vm.message.copyText
        ? _c(
            "p",
            [
              _vm._l(_vm.message.buttons, function (button, index) {
                return _c(
                  "button",
                  {
                    key: _vm.message.id + "-" + index,
                    class: [_vm.buttons.medium, _vm.buttons.gray],
                    on: {
                      click: function ($event) {
                        return button.handler()
                      },
                    },
                  },
                  [_vm._v(_vm._s(button.text))]
                )
              }),
              _vm._v(" "),
              _vm.message.copyText
                ? _c(
                    "button",
                    {
                      class: [_vm.buttons.medium, _vm.buttons.blue],
                      on: { click: _vm.doCopy },
                    },
                    [_vm._v(_vm._s(_vm.$t("Copy")))]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }