import _ from 'underscore';

export default class {
    constructor (src) {
        Object.assign(this, src);
        this.exactPath = this.exactPath === null || this.exactPath === undefined || this.exactPath;
        if (!this.routeName) { this.routeName = this.moduleName + '-' + this.handler; }
    }

    isCurrent (route) {
        return this.exactPath && this.extraPaths ? this.extraPaths && _.some(this.extraPaths, xp => xp.test(route.fullPath)) : (route.name === this.routeName) || (this.extraPaths && _.some(this.extraPaths, xp => xp.test(route.fullPath)));
    }
}
