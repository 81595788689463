import './init/legacy';
import initVue from './init/vue';
import initRouter from './init/router';
import Vue from 'vue';
import 'css-mixins/darktheme.css';
import initLocale from './init/locale';
import initServiceDiscovery from './init/serviceDiscovery';
import initDialogs from './init/dialogs';
import initGlobalErrors from './init/globalErrors';
import MessageModel from './models/Message';
import preauth from './init/preauth';
import storage from 'fb-utils/storage';

const app = window.AdminApp = {
    user: {
        get: function () {
            return '';
        }
    },
    options: window.__config,
    updateQuery (newQuery) {
        app.router.replace({query: newQuery}).catch(() => 0);
    },
    showMessage (message) {
        if (typeof message === 'string') { message = new MessageModel({text: message}); }
        if (!(message instanceof MessageModel)) { message = new MessageModel(message); }
        app.Vue.$refs.rootView.showMessage(message);
    }
};
(async function () {
    document.body.dataset.theme = storage.local.get('theme');
    await initServiceDiscovery(app);
    await preauth();
    const directives = require.context('core/init/vue/directives', true, /.*.js/);
    const dirs = {};
    directives.keys().forEach(d => {
        dirs[d.split(/[./]+/)[1]] = directives(d);
    });
    await initLocale(app);
    await initDialogs(app);
    await initVue();
    await initGlobalErrors(app);
    const router = await initRouter(app);

    window.AdminApp.Vue = new Vue({
        el: document.getElementById('__root__'),
        router,
        render: (h) => h('router-view', {ref: 'rootView'}),
        directives: dirs
    });
})();
