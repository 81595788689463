import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }

const menu = [
    {
        'inMainMenu': true,
        'category': 'global',
        'name': 'Payments',
        'icon': fa['credit-card'],
        'path': 'Payment/Search',
        'handler': 'Payments',
        'index': 30,
        // 'extraPaths': ['Payment/Search.*'],
        'requirePermissions': ['Admin.Users.Payments']
    },
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'SessionReports',
        routeName: 'Search-ErrorReports-Publisher',
        'icon': fa.bug,
        defaults: {
            serverName: 'Publisher2'
        },
        'path': 'Error-Reports/:serverName?',
        'handler': 'ErrorReports',
        'index': 70,
        exactPath: true,
        'requirePermissions': ['Admin.Products.ErrorReports'],
        'extraPaths': [/^\/Error-Reports\/Publisher2(\/.*)?/]
    },
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'SessionReportsAutomation',
        routeName: 'Search-ErrorReports-Automation',
        'icon': fa.bug,
        defaults: {
            serverName: 'PublisherAutomation'
        },
        'path': 'Error-Reports/:serverName?',
        'handler': 'ErrorReports',
        'index': 71,
        exactPath: true,
        'requirePermissions': ['Admin.Products.ErrorReports'],
        'extraPaths': [/^\/Error-Reports\/PublisherAutomation(\/.*)?/]
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'Publications',
        'icon': fa.book,
        'path': 'Content/PublisherCloud',
        'handler': 'CldBz',
        'index': 12,
        'requirePermissions': ['Admin.PublisherCloud.Publications']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'FbPublicationsTitle',
        'icon': fa.columns,
        'path': 'Content/FBOnline',
        'handler': 'Fbo',
        'index': 20,
        exactPath: true,
        'requirePermissions': ['Admin.FBOnline.Publications']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'AuditLog',
        'icon': fa.unlock,
        'path': 'Audit',
        'handler': 'Audit',
        'index': 30,
        'requirePermissions': ['Admin.Audit']
        // 'extraPaths': ['Audit/.*']
    },
    {
        'inMainMenu': true,
        'category': 'global',
        'name': 'CRM',
        'icon': fa.list,
        'path': 'CRM',
        'handler': 'Users',
        'index': 10,
        'requirePermissions': ['Admin.Users']
    },
    {
        'inMainMenu': true,
        'category': 'global',
        'name': 'CRMReports',
        'icon': fa.database,
        'path': 'SearchReports/:server?',
        // 'extraPaths': ['SearchReports(/.*)?'],
        'handler': 'Reports',
        'index': 11,
        'requirePermissions': ['Admin.Users']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'FboCustomDomainsTitle',
        'icon': fa.globe,
        'path': 'Content/FBOnline/CustomDomains',
        'handler': 'CustomDomains',
        'index': 20,
        'requirePermissions': ['Admin.FBOnline.Publications.Edit']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'HappyFarmJobs',
        'icon': fa.cubes,
        'path': 'Content/FarmJobs/:serverName?',
        'handler': 'Farm',
        'index': 90,
        'requirePermissions': ['Admin.Farms.JobControl']
        // 'extraPaths': ['Content/FarmJobs(/.*)?']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Emails',
        'icon': fa.envelope,
        'path': 'Mails',
        'handler': 'Mails',
        'index': 40,
        'requirePermissions': ['Admin.Mailer']
        // 'extraPaths': ['Mails/.*']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'PushNotifications',
        'icon': fa['bell-o'],
        'path': 'Push/Messages',
        'handler': 'Pushes',
        'index': 45,
        'requirePermissions': ['Admin.Mailer.Push']
        // 'extraPaths': ['Mails/.*']
    }
];
export {menu};
