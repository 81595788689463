import LoggedInLayout from './LoggedInLayout';
import _ from 'underscore';
import allManifests from './allManifests';
import NotFound from './NotFound';
import AccessDenied from './AccessDenied';

const routes = [
    {
        path: '/access-denied',
        component: AccessDenied,
        meta: {
            unauth: true
        }
    },
    {
        path: '/',
        component: LoggedInLayout,
        children: _.chain(allManifests)
            .map(mft => mft.menu.map(item => {
                item._manifest = mft;
                return item;
            }))
            .flatten()
            .map(x => ({
                name: x.routeName || (x._manifest.moduleName + '-' + x.handler),
                path: x.path,
                component: () => new Promise(resolve => x._manifest.load(resolve)).then(z => {
                    return typeof z[x.handler] === 'function' ? z[x.handler]() : z[x.handler];
                }),
                children: x.children ? x.children.map(child => ({
                    name: child.routeName || (x._manifest.moduleName + '-' + x.handler + '-' + child.handler),
                    path: child.path,
                    component: () => new Promise(resolve => x._manifest.load(resolve)).then(z => {
                        return typeof z[child.handler] === 'function' ? z[child.handler]() : z[child.handler];
                    })
                })) : null,
                props: x.props || true
            })).value()
    }
];
routes[1].children.push({
    path: '*',
    component: NotFound
});
export default routes;
