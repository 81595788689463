import moment from 'moment';

export default (val, fmt) => {
    if (!val) {
        return '';
    }
    const date = moment.utc(val);
    if (date.isValid()) {
        const d = date.diff(moment(), 'days');
        if (Math.abs(d) > 3) {
            return date.format(fmt || 'LL');
        }
        return date.fromNow();
    }
    return val;
};
