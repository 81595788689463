import productNames from 'locale/ProductNames.json';
import pnl from './productNameLocalized';
import _ from 'underscore';
import currentLocale from '../core/currentLocale';

module.exports = function (name, slice) {
    if (name === 'N/A') { return 'N/A'; }
    if (!name) { return null; }
    if (slice) {
        name = name.split('/').slice(slice).join('/');
    }
    const safe = name.replace('/', '_');
    let last = safe.length;
    let result = '';
    do {
        let part = safe.substring(0, last);
        part = currentLocale.translate(productNames, part);
        if (part) {
            result += part + ' ';
            break;
        }
        last = name.lastIndexOf('/', last - 1);
    } while (last >= 0);
    if (last !== safe.length) {
        result += _.map(name.substring(last + 1).split('/'), pnl).join(' ');
    }
    return result.trim();
};
