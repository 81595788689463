import fa from '../../css-mixins/fontawesome.css';

export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }

const menu = [
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'HelpPages',
        'icon': fa['question-circle'],
        'path': 'SitePages/Help',
        'handler': 'HelpPageTree',
        props: {root: 'help'},
        'index': 70,
        routeName: 'Help-Help',
        'requirePermissions': ['Admin.Site.Pages'],
        extraPaths: [/SitePages\/Edit\/help\/.*/i, /SitePages\/help/i]
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'BlogPages',
        'icon': fa.wordpress,
        'path': 'SitePages/Blog',
        props: {root: 'blog'},
        'handler': 'HelpPageTree',
        'index': 71,
        routeName: 'Help-Blog',
        'requirePermissions': ['Admin.Site.Pages'],
        extraPaths: [/SitePages\/Edit\/blog\/.*/i, /SitePages\/blog/i]
    },
    {
        'inMainMenu': false,
        'path': 'SitePages',
        'handler': 'HelpPageTree'
    },
    {
        'inMainMenu': false,
        'path': 'SitePages/Edit/:path*',
        'handler': 'HelpPageEdit'
    },
    {
        'inMainMenu': false,
        'path': 'SitePages/Tags',
        'handler': 'HelpTags'
    }
];
export {menu};
