import 'moment/locale/ru';
import 'moment/locale/en-gb';
import currentLocale from '../currentLocale';

export default function (app) {
    let locale = app.options.locale;
    if (locale !== 'ru' && locale !== 'en') {
        locale = 'ru';
    }
    currentLocale.set(locale);
}
