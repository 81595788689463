const manifests = require.context('../modules', true, /manifest.js/);

const realManifests = {};
manifests.keys().forEach(k => {
    const parts = k.split('/');
    if (parts[0] === '.') { return; }
    manifests(k).moduleName = parts[1];
    realManifests[k] = manifests(k);
});
export default realManifests;
