<template>
    <div :class="wrapperClasses">
        <label :for="elementId" :class="$style.materialLabel" v-if="$slots.default">
            <slot/>
        </label>
        <select ref="select" v-bind="inputAttributes" v-bind:value="selectedValue" v-on="inputListeners" :disabled="disabled">
            <option v-for="(option,optionVal) in options" :key="optionVal" :value="optionVal">{{isComplexKey?option.text:option}}</option>
        </select>
        <small :class="$style.materialNote" v-if="descriptionProvided">{{description}}</small>
        <p v-if="!isValid" :class="$style.materialValidationError">{{validationErrorText}}</p>
    </div>
</template>

<script>
import IdGen from 'vue_modules/utils/id_gen';
import _ from 'underscore';
import Validation from '../Validation';

export default {
    mixins: [Validation],
    props: {
        description: {
            type: String,
            default: undefined
        },
        value: [String, Number, Object, Array, Boolean],
        disabled: Boolean,
        options: [Object, Array]
    },
    data () {
        return {
            focused: false,
            isComplexKey: _.isArray(this.options)
        };
    },
    computed: {
        elementId () {
            return this.inputAttributes.id;
        },
        selectedValue () {
            if (this.isComplexKey) {
                const i = _.findIndex(this.options, x => x.value === this.value);
                if (i === -1) { return null; }
                return i;
            } else {
                return this.value;
            }
        },
        descriptionProvided () {
            return (
                (this.description !== undefined || this.description !== null) &&
                this.description !== ''
            );
        },
        inputAttributes () {
            const defaults = {
                id: IdGen(),
                autocomplete: 'off',
                type: 'text'
            };
            const attrsOverride = {
                class: [this.$style.materialInput, {[this.$style.materialInputInvalid]: !this.isValid}]
            };
            return Object.assign({}, defaults, this.$attrs, attrsOverride);
        },
        inputListeners: function () {
            const vm = this;
            return {
                change: function (event) {
                    if (vm.isComplexKey) {
                        vm.$emit('input', vm.options[Number(event.target.value)].value);
                    } else {
                        vm.$emit('input', event.target.value);
                    }
                },
                focus: function (event) {
                    return vm.toggleFocus(event);
                },
                blur: function (event) {
                    return vm.toggleFocus(event);
                }
            };
        },

        wrapperClasses () {
            return [
                this.$style.materialWrapper,
                this.hasContent() ? this.$style.materialWrapperNotEmpty : '',
                this.focused ? this.$style.materialWrapperActive : ''
            ];
        }
    },
    methods: {
        toggleFocus () {
            this.focused = !this.focused;
        },
        hasContent () {
            return true;
            // return (this.selectedValue !== null && this.selectedValue !== undefined);
        }
    }
};
</script>
<style module src="../../../css-mixins/inputs.css" />
