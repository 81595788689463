<template>
    <div :class="{[$style.fixScrollable]:fixScrollable}" key="mdLoadScreenDeduplicationGuard">
        <md-overlay v-if="isLoading" :class="$style.overlay">
            <gears :background="background" v-if="!showTable" />
            <tables v-if="showTable" />
        </md-overlay>
        <slot v-if="!isLoading || loadedOnce" v-show="!isLoading"></slot>
    </div>
</template>
<style module>
    .fixScrollable{
        position:relative;
        min-height: 30em;
    }
    .overlay{
        background: var(--default-background);
    }
</style>
<script>
import MdOverlay from 'vue_modules/MdComponents/MdOverlay';
import tables from './Table';
import gears from './Gears';

export default {
    name: 'md-load-screen',
    inheritAttrs: false,
    props: ['isLoading', 'background', 'fixScrollable'],
    data () {
        return {
            showTable: Math.random() > 0.8,
            loadedOnce: false
        };
    },
    watch: {
        isLoading (newValue) {
            this.loadedOnce |= newValue;
        }
    },
    components: Object.assign(
        {
            gears: gears,
            tables: tables
        },
        MdOverlay
    )
};
</script>
