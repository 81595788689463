import auth from './auth';
export default function (text) {
    return fetch('/_api_/ReportMisbehavior', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            AccessToken: auth.instance.token
        },
        body: JSON.stringify({
            Text: text
        })
    });
}
