import fa from '../../css-mixins/fontawesome.css';

export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }

const menu =
[
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'Backlinks',
        'icon': fa['external-link'],
        'path': 'Content/Backlinks/:serverName?',
        'handler': 'Backlinks',
        'index': 200,
        'requirePermissions': ['Admin.Backlinks']
        // 'extraPaths': ['Content/Backlinks(/.*)?']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'FboForbiddenLinks',
        'icon': fa.unlink,
        'path': 'Content/FBOnline/ForbiddenLinks',
        'handler': 'FBOnlineForbiddenLinks',
        'index': 201,
        'requirePermissions': ['Admin.RemoteConfiguration']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'PublLinkDecoder',
        'icon': fa.unlink,
        'path': 'LinkDecoder',
        'handler': 'LinkDecoder',
        'index': 100,
        'requirePermissions': ['Admin.PublisherCloud']
    }];
export {menu};
