import Enum from './enum';
import _ from 'underscore';
import fboEnums from 'utils/enums/fbonlineBackend.download';
import statEnums from 'utils/enums/statistics.download';
import paymentsEnums from 'utils/enums/payments.download';
import licensingEnums from 'utils/enums/licensing.download';

function makeEnum (simpleDescription) {
    return _.object(_.map(simpleDescription, (v, k) => [
        '' + v,
        {Value: k, Description: k, UnderlayingValue: '' + v}
    ]));
}

const proto = {
    SSOValidationMessage: new Enum({
        '0': {Value: 'ValueTooShort', UnderlayingValue: 0},
        '1': {Value: 'ValueMandatory', UnderlayingValue: 1},
        '2': {Value: 'InvalidValue', UnderlayingValue: 2},
        '3': {Value: 'DuplicateValue', UnderlayingValue: 3},
        '4': {Value: 'ValueTooLong', UnderlayingValue: 4},
        '5': {Value: 'NeedStrongerToken', UnderlayingValue: 5},
        '6': {Value: 'WrongData', UnderlayingValue: 6}
    }),
    SSOWellKnownError: new Enum(makeEnum({
        UserNotFound: 0,
        InvalidCertificate: 1,
        AuthenticationFailed: 2,
        InvalidAccessToken: 3,
        AccessDenied: 4,
        InvalidData: 5,
        ConstraintViolation: 6
    })),
    SSOSecurityPolicy: new Enum(makeEnum({
        DisableIPChecks: 1,
        RequireMFA: 2,
        DisableDirectLogon: 4,
        SAML2SSOOwner: 8,
        SAML2SSORelated: 16,
        DisableExternalLogon: 32
    }), true),
    ValidationMessage: new Enum({
        '0': {Value: 'ValueTooShort', UnderlayingValue: 0},
        '1': {Value: 'ValueMandatory', UnderlayingValue: 1},
        '2': {Value: 'InvalidValue', UnderlayingValue: 2},
        '3': {Value: 'DuplicateValue', UnderlayingValue: 3},
        '4': {Value: 'ValueTooLong', UnderlayingValue: 4},
        '5': {Value: 'WrongData', UnderlayingValue: 5}
    }),
    // TransitionType: new Enum({ '1': { 'Value': 'Initial', 'Description': 'Initial', 'UnderlayingValue': '1' }, '2': { 'Value': 'Renewal', 'Description': 'Renewal', 'UnderlayingValue': '2' }, '4': { 'Value': 'Upgrade', 'Description': 'Upgrade', 'UnderlayingValue': '4' }, '8': { 'Value': 'Subscription', 'Description': 'Subscription', 'UnderlayingValue': '8' }, '16': { 'Value': 'Cancellation', 'Description': 'Cancellation', 'UnderlayingValue': '16' }, '1024': { 'Value': 'PeriodSwitch', 'Description': 'PeriodSwitch', 'UnderlayingValue': '1024' }, '32': { 'Value': 'Monthly', 'Description': 'Monthly', 'UnderlayingValue': '32' }, '64': { 'Value': 'Yearly', 'Description': 'Yearly', 'UnderlayingValue': '64' }, '128': { 'Value': 'Daily', 'Description': 'Daily', 'UnderlayingValue': '128' }, '256': { 'Value': 'Weekly', 'Description': 'Weekly', 'UnderlayingValue': '256' }, '512': { 'Value': 'Quarterly', 'Description': 'Quarterly', 'UnderlayingValue': '512' }, '992': { 'Value': 'PeriodMask', 'Description': 'PeriodMask', 'UnderlayingValue': '992' } }, true),
    LicenseSource: new Enum({
        '1': {'Value': 'Purchase', 'Description': 'Purchase', 'UnderlayingValue': '1'},
        '2': {'Value': 'Reseller', 'Description': 'Reseller', 'UnderlayingValue': '2'},
        '4': {'Value': 'Admin', 'Description': 'Admin', 'UnderlayingValue': '4'},
        '8': {'Value': 'Other', 'Description': 'Other', 'UnderlayingValue': '8'},
        '16': {'Value': 'Assignment', 'Description': 'Assignment', 'UnderlayingValue': '16'},
        '32': {'Value': 'Rollback', 'Description': 'Rollback', 'UnderlayingValue': '32'},
        '64': {'Value': 'Superuser', 'Description': 'Superuser', 'UnderlayingValue': '64'}
    }, true),
    // PaymentProcessor: new Enum({ '0': { 'Value': 'Cleverbridge', 'Description': 'Cleverbridge', 'UnderlayingValue': '0' }, '1': { 'Value': 'PayPro', 'Description': 'PayPro', 'UnderlayingValue': '1' } }),
    NotificationPriority: new Enum({
        '0': {'Description': 'Low', 'Value': 'Low', 'UnderlayingValue': '0'},
        '1': {'Description': 'Normal', 'Value': 'Normal', 'UnderlayingValue': '1'},
        '2': {'Description': 'High', 'Value': 'High', 'UnderlayingValue': '2'},
        '3': {'Description': 'Urgent', 'Value': 'Urgent', 'UnderlayingValue': '3'}
    }, false),
    ReportAccess: new Enum({
        '0': {'Value': 'Public', 'Description': 'Public', 'UnderlayingValue': '0'},
        '1': {'Value': 'System', 'Description': 'System', 'UnderlayingValue': '1'},
        '2': {'Value': 'Private', 'Description': 'Private', 'UnderlayingValue': '2'},
        '3': {'Value': 'Archived', 'Description': 'Archived', 'UnderlayingValue': '3'}
    }),
    MailStatus: new Enum({
        '0': {'Description': 'Queued', 'Value': 'Queued', 'UnderlayingValue': '0'},
        '1': {'Description': 'Sent', 'Value': 'Sent', 'UnderlayingValue': '1'},
        '2': {'Description': 'NotAccepted', 'Value': 'NotAccepted', 'UnderlayingValue': '2'},
        '3': {'Description': 'Postponed', 'Value': 'Postponed', 'UnderlayingValue': '3'},
        '4': {'Description': 'Error', 'Value': 'Error', 'UnderlayingValue': '4'}
    }, false),
    StatisticsAttributeType: new Enum(makeEnum({ Continuous: 0, Discrete: 1, Numeric: 0, Text: 2, LongText: 4, Boolean: 8, Time: 16 }), true),
    StatisticsAxisType: new Enum(makeEnum({ time: 0, count: 1, attr: 2 }), false),
    // PeriodType: new Enum({ '0': { 'Description': 'None', Value: 'None', UnderlayingValue: '0' }, '32': { 'Description': 'Monthly', Value: 'Monthly', UnderlayingValue: '32' }, '64': { 'Description': 'Yearly', Value: 'Yearly', UnderlayingValue: '64' }, '128': { 'Description': 'Daily', Value: 'Daily', UnderlayingValue: '128' }, '256': { 'Description': 'Weekly', Value: 'None', UnderlayingValue: '256' }, '512': { 'Description': 'Quarterly', Value: 'Quarterly', UnderlayingValue: '512' } }, true)
    CoordinatorJobStatus: new Enum({
        '0': {'Description': 'Queued', 'Value': 'Queued', 'UnderlayingValue': '0'},
        '1': {'Description': 'Running', 'Value': 'Running', 'UnderlayingValue': '1'},
        '2': {'Description': 'Error', 'Value': 'Error', 'UnderlayingValue': '2'},
        '3': {'Description': 'Completed', 'Value': 'Completed', 'UnderlayingValue': '3'}
    }, false),
    CoordinatorJobState: new Enum({
        '0': {'Description': 'Queued', 'Value': 'Queued', 'UnderlayingValue': '0'},
        '1': {'Description': 'Running', 'Value': 'Running', 'UnderlayingValue': '1'},
        '3': {'Description': 'Error', 'Value': 'Error', 'UnderlayingValue': '3'},
        '2': {'Description': 'Completed', 'Value': 'Completed', 'UnderlayingValue': '2'}
    }, false),
    LockoutMethod: new Enum(makeEnum({
        None: 0,
        DisablePasswordLogon: 1,
        FlushTokens: 2,
        Ban: 4
    }), true),
    MailState: new Enum(makeEnum({ None: 0, InQueue: 1, Sent: 2, Postponed: 4, Unsubscribed: 8, TemplateError: 16, TransportError: 32 }), true)
};
_.each(licensingEnums.Enums, function (e) {
    proto[e.Name] = new Enum(e.Values, e.IsFlags);
});
_.each(fboEnums.Enums, function (e) {
    proto[e.Name] = new Enum(e.Values, e.IsFlags);
});
_.each(statEnums.Enums, function (e) {
    proto[e.Name] = new Enum(e.Values, e.IsFlags);
});
_.each(paymentsEnums.Enums, function (e) {
    proto[e.Name] = new Enum(e.Values, e.IsFlags);
});
module.exports = proto;
