import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueI18nFilter from 'vue-i18n-filter';
import localeMixin from 'vue_modules/localeMixin';
import portal from 'portal-vue';
import interpolationComponent from 'utils/vue-i18n-iterpolation-component';

export default function () {
    Vue.use(VueI18n);
    Vue.use(portal);
    Vue.use(VueI18nFilter);
    Vue.mixin(localeMixin);

    Vue.component(interpolationComponent.name + 'x', interpolationComponent);

    VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
        if (this.locale !== 'ru') {
            return choice === 1 ? 0 : Math.min(choicesLength, 1);
        }

        if (choice === 0) {
            return 0;
        }

        const teen = choice > 10 && choice < 20;
        const endsWithOne = choice % 10 === 1;

        if (!teen && endsWithOne) {
            return 1;
        }

        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
        }

        return (choicesLength < 4) ? 2 : 3;
    };
    const directives = require.context('core/init/vue/directives', true, /.*.js/);
    directives.keys().forEach(d => {
        Vue.directive(d.split(/[./]+/)[1], directives(d));
    });
    const filters = require.context('core/init/vue/filters', true, /.*.js/);
    filters.keys().forEach(d => Vue.filter(d.split(/[./]+/)[1], filters(d).default));
}
