<i18n>
{
    "ru": {
        "notFound": "Такой страницы не существует"
    },
    "en": {
        "notFound": "That page does not exists"
    }
}
</i18n>
<template>
    <div :class="$style.wrapper">
        <h2>{{$t('notFound')}}</h2>
    </div>
</template>
<style module>
.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>
<script>
export default {
};
</script>
