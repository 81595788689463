
let overlayId = 0;
export default function (app) {
    app.showDialog = function (dialogView, options) {
        let _resolve;
        const removeDeferred = new Promise((resolve) => {
            _resolve = resolve;
        });
        const overlayObj = {
            component: dialogView,
            id: overlayId++,
            ...options,
            close (data) {
                app.Vue.$refs.rootView.removeOverlay(overlayObj);
                _resolve(data);
            }
        };
        app.Vue.$refs.rootView.addOverlay(overlayObj);
        return removeDeferred;
    };

    app.hideDialog = function () {
        let p = null;
        const dlg = app.__currentDialog;
        if (dlg) {
            dlg.trigger('hide');
            if (dlg.waitHideAnimation) {
                const that = app;

                p = new Promise(function (resolve) {
                    dlg.once(dlg.waitHideAnimation, function () {
                        dlg.remove();
                        resolve();
                        that.__lastDialogHide = null;
                    });
                });
                app.__lastDialogHide = p;
            } else {
                dlg.remove();
            }
            app.__currentDialog = null;
        }
        return p;
    };
}
