var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MdDialog",
    {
      ref: "dialog",
      attrs: { width: "400px", title: _vm.$t("title") },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "a",
                {
                  class: [_vm.buttons.gray, _vm.buttons.medium],
                  attrs: { href: `${_vm.logout}?returnUrl=${_vm.href}` },
                  on: { click: _vm.doLogout },
                },
                [_vm._v(_vm._s(_vm.$t("logout")))]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { flex: "1 0 auto" } }),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: [_vm.buttons.blue, _vm.buttons.medium],
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("MdLoadScreen", { attrs: { "is-loading": _vm.loading } }, [
        _c(
          "div",
          { class: _vm.$style.form },
          [
            _c(
              "MdTextInput",
              {
                attrs: { "input-type": "number", min: "10", max: "1000" },
                model: {
                  value: _vm.pageSize,
                  callback: function ($$v) {
                    _vm.pageSize = $$v
                  },
                  expression: "pageSize",
                },
              },
              [_vm._v(_vm._s(_vm.$t("pageSize")))]
            ),
            _vm._v(" "),
            _c(
              "MdCombo",
              {
                attrs: {
                  options: {
                    FTS: _vm.$t("emailSearchTypeFTS"),
                    Start: _vm.$t("emailSearchTypeStart"),
                    Like: _vm.$t("emailSearchTypeLike"),
                  },
                },
                model: {
                  value: _vm.emailSearchType,
                  callback: function ($$v) {
                    _vm.emailSearchType = $$v
                  },
                  expression: "emailSearchType",
                },
              },
              [_vm._v(_vm._s(_vm.$t("emailSearchType")))]
            ),
            _vm._v(" "),
            _c(
              "MdCombo",
              {
                attrs: {
                  options: {
                    "": _vm.$t("languageAutomatic"),
                    ru: _vm.$t("languageRussian"),
                    en: _vm.$t("languageEnglish"),
                  },
                },
                model: {
                  value: _vm.language,
                  callback: function ($$v) {
                    _vm.language = $$v
                  },
                  expression: "language",
                },
              },
              [_vm._v(_vm._s(_vm.$t("language")))]
            ),
            _vm._v(" "),
            _c(
              "MdCombo",
              {
                attrs: {
                  options: {
                    normal: _vm.$t("themeNormal"),
                    dark: _vm.$t("themeDark"),
                  },
                },
                model: {
                  value: _vm.theme,
                  callback: function ($$v) {
                    _vm.theme = $$v
                  },
                  expression: "theme",
                },
              },
              [_vm._v(_vm._s(_vm.$t("theme")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }