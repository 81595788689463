export default {
    props: {
        isValid: {
            type: Boolean,
            default: true
        },
        validationErrorText: String
    },
    watch: {
        isValid (_new, _old) {
            if (_new !== _old) { this.$emit('validation', _new); }
        }
    }
};
