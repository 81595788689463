var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MdOverlay",
    {
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.maybeClose.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          class: [_vm.$style.Wrapper, _vm.contentClass],
          style: {
            width: _vm.width,
            height: _vm.height,
            "max-height": _vm.height === "auto" ? "90%" : null,
          },
          on: {
            mousedown: function ($event) {
              _vm.x__clickedElement = $event.target
            },
            mouseup: function ($event) {
              _vm.x__clickedElement = null
            },
          },
        },
        [
          _c("div", { class: _vm.$style.Header }, [
            _vm.title
              ? _c("h6", { class: _vm.$style.Title }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              class: _vm.$style.Close,
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.$slots.footer
            ? _c("div", { class: _vm.$style.Footer }, [_vm._t("footer")], 2)
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }