import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'global',
        'name': 'SearchTitle',
        'icon': fa.user,
        'path': 'User/Search',
        'handler': 'QuickSearch',
        'index': 0,
        'requirePermissions': ['Admin.Users'],
        props: route => ({initQuery: route.query.query, page: Math.max(0, Number(route.query.page) - 1)})
        // 'extraPaths': ['User/.*']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'UserGroups',
        'icon': fa.users,
        'path': 'UserGroups',
        'handler': 'Groups',
        'requirePermissions': ['Admin.Users.Edit.Permissions']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'PasswordPolicies',
        'icon': fa.lock,
        'path': 'PasswordPolicies/:id?',
        'handler': 'PasswordPolicies',
        'requirePermissions': ['Admin.Users.Edit.Permissions']
    },
    {
        'inMainMenu': false,
        'path': 'User/:userId/Edit',
        'handler': 'Edit'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/Permissions',
        'handler': 'Permissions'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/LocalStorage',
        'handler': 'LocalStorage'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/Api',
        'handler': 'ApiKeys'
    },
    {
        'inMainMenu': false,
        'path': 'User/:userId/FBOnline',
        'handler': 'FBOnline'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/Relations',
        'handler': 'Relations'
    },
    {
        'inMainMenu': false,
        'path': 'Push/Devices/:id',
        'handler': 'PushDevices'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/SAML',
        'handler': 'Saml'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/ResellerLicenses',
        'handler': 'ResellerLicenses',
        children: [
            {
                path: '',
                handler: 'ResellerLicensesList'
            },
            {
                path: 'Buy',
                handler: 'ResellerLicensesBuy'
            },
            {
                path: 'Upgrade/:targetId?',
                handler: 'ResellerLicensesUpgrade',
                props: true
            }
        ]
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/Payments',
        'handler': 'Payments'
    },
    {
        'inMainMenu': false,
        'path': 'User/:userId/automation',
        'handler': 'AutomationView'
    },
    {
        'inMainMenu': true,
        'category': 'settings',
        'name': 'CrmAutomationSettings',
        'icon': fa.user,
        'index': 33,
        'requirePermissions': ['Admin.Automation'],
        'path': 'crm/automation',
        'handler': 'AutomationConfigView'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/PossiblePayments',
        'handler': 'PossiblePayments'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/Licenses',
        'handler': 'LicensesView'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/License/:server/:licenseId/Installations',
        'handler': 'Installations'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/CldBz',
        'handler': 'CldBz'
    },
    {
        'inMainMenu': false,
        'path': 'User/:id/MailSubscriptions',
        'handler': 'Subscriptions'
    }
];
export {menu};
