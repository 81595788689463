import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }

const menu = [
    {
        'inMainMenu': true,
        'category': 'settings',
        'name': 'HappyFarmConfig',
        'icon': fa.cutlery,
        'path': 'Settings/Farm/:server?',
        'handler': 'Settings',
        'index': 90,
        'requirePermissions': ['Admin.Farms.Config']
        // 'extraPaths': ['Settings/Farm(/.*)?$']
    },
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'HappyFarmJobsRT',
        'icon': fa.cutlery,
        'path': 'Settings/FarmStatus/:server?',
        'handler': 'Status',
        'index': 91,
        'requirePermissions': ['Admin.Farms.Config']
        // 'extraPaths': ['Settings/FarmStatus(/.*)?']
    }
];
export {menu};
