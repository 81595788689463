import Currencies from '../../../../utils/currencies.download';

export default (val, currency, zeroNegatives) => {
    if (!Currencies[currency]) {
        console.log('No currency info for ', currency);
        return val + ' ' + currency;
    }
    if (zeroNegatives && val <= 0) { return Currencies[currency].Format(0); }
    return Currencies[currency].Format(val);
};
