export default class {
    constructor (base, values) {
        this._enum = base;
        this._reset(values);
    }

    _reset (values) {
        this.SetValues = values;
        this.SetFlags = {};
        for (let i = 0; i < this.SetValues.length; i++) {
            this.SetFlags[this.SetValues[i].Value] = true;
        }
    }

    Descriptions (sep) {
        const parts = [];
        for (let i = 0; i < this.SetValues.length; i++) {
            parts.push(this.SetValues[i].Description);
        }
        if (!sep) {
            sep = ', ';
        }
        return parts.join(sep);
    }

    Values (sep, trFunc) {
        const parts = [];
        for (let i = 0; i < this.SetValues.length; i++) {
            if (trFunc) { parts.push(trFunc(this.SetValues[i].Value)); } else { parts.push(this.SetValues[i].Value); }
        }
        if (!sep) {
            sep = ', ';
        }
        return parts.join(sep);
    };

    HasFlag (flag) {
        if (!this._enum.IsFlags) {
            return flag === this.SetValues[0].Value;
        }
        for (let i = 0; i < this.SetValues.length; i++) {
            if (this.SetValues[i].Value === flag) {
                return true;
            }
        }
        return false;
    };

    AddFlag (flag) {
        for (let i = 0; i < this._enum.Values.length; i++) {
            if (this._enum.Values[i].Value === flag || this._enum.Values[i].UnderlayingValue === flag) {
                this.SetValues.push(this._enum.Values[i]);
                this.SetFlags[this._enum.Values[i].Value] = true;
                break;
            }
        }
    };

    RemoveFlag (flag) {
        for (let i = 0; i < this._enum.Values.length; i++) {
            if (this._enum.Values[i].Value === flag || this._enum.Values[i].UnderlayingValue === flag) {
                for (let j = 0; j < this.SetValues.length; j++) {
                    if (this.SetValues[j] === this._enum.Values[i]) {
                        this.SetValues.splice(j, 1);
                        break;
                    }
                }
                this.SetFlags[this._enum.Values[i].Value] = false;
                break;
            }
        }
    };

    UnderlayingValue () {
        let ret = 0;
        for (let i = 0; i < this.SetValues.length; i++) {
            ret |= this.SetValues[i].UnderlayingValue;
        }
        return ret;
    };

    get Value () {
        return this.UnderlayingValue();
    }

    set Value (v) {
        this._reset(this._enum.Parse(v).SetValues);
    }

    get Enum () {
        return this._enum;
    }
}
