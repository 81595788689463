var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: "mdLoadScreenDeduplicationGuard",
      class: { [_vm.$style.fixScrollable]: _vm.fixScrollable },
    },
    [
      _vm.isLoading
        ? _c(
            "md-overlay",
            { class: _vm.$style.overlay },
            [
              !_vm.showTable
                ? _c("gears", { attrs: { background: _vm.background } })
                : _vm._e(),
              _vm._v(" "),
              _vm.showTable ? _c("tables") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading || _vm.loadedOnce ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }