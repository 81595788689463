<i18n>
{
    "ru": {
        "title": "Пользовательские настройки",
        "pageSize": "Размер страницы списков",
        "save": "Сохранить",
        "logout": "Выход",
        "emailSearchType": "Режим поиска пользователей",
        "emailSearchTypeFTS": "По частям email (оптимально)",
        "emailSearchTypeStart": "По префиксу (довольно быстро)",
        "emailSearchTypeLike": "По подстроке (очень медленно)",
        "language": "Язык",
        "languageAutomatic": "Определять автоматически",
        "languageRussian": "Русский",
        "languageEnglish": "Английский",
        "theme": "Тема оформления",
        "themeNormal": "Обычная",
        "themeDark": "Чорний"
    },
    "en": {
        "title": "User settings",
        "pageSize": "List page size",
        "save": "Save",
        "logout": "Logout",
        "emailSearchType": "User search mode",
        "emailSearchTypeFTS": "By email parts (optimal)",
        "emailSearchTypeStart": "By prefix (rather fast)",
        "emailSearchTypeLike": "By substring (very slow)",
        "language": "Language",
        "languageAutomatic": "Detect automatically",
        "languageRussian": "Russian",
        "languageEnglish": "English",
        "theme": "Display theme",
        "themeNormal": "Normal",
        "themeDark": "BL∀CK"
    }
}
</i18n>
<template>
    <MdDialog ref="dialog" width="400px" :title="$t('title')">
        <MdLoadScreen :is-loading="loading">
            <div :class="$style.form">
                <MdTextInput input-type="number" min="10" max="1000" v-model="pageSize">{{$t('pageSize')}}</MdTextInput>
                <MdCombo v-model="emailSearchType" :options="{FTS:$t('emailSearchTypeFTS'),Start:$t('emailSearchTypeStart'), Like:$t('emailSearchTypeLike')}">{{$t('emailSearchType')}}</MdCombo>
                <MdCombo v-model="language" :options="{'':$t('languageAutomatic'),ru:$t('languageRussian'),en:$t('languageEnglish')}">{{$t('language')}}</MdCombo>
                <MdCombo v-model="theme" :options="{normal:$t('themeNormal'),dark:$t('themeDark')}">{{$t('theme')}}</MdCombo>
            </div>
        </MdLoadScreen>
        <template #footer>
            <a :class="[buttons.gray, buttons.medium]" @click="doLogout" :href="`${logout}?returnUrl=${href}`">{{$t('logout')}}</a>
            <span style="flex: 1 0 auto"></span>
            <button :class="[buttons.blue, buttons.medium]" @click="save" type="button">{{$t('save')}}</button>
        </template>
    </MdDialog>
</template>

<script>
import BaseComponent from '../vue_modules/BaseComponent';
import auth from './auth';
import MdTextInput from '../vue_modules/MdComponents/MdTextInput/MdTextInput';
import MdDialog from '../vue_modules/MdComponents/MdDialog/MdDialog';
import pageSize from '../vue_modules/MdComponents/Pager/pageSize';
import MdCombo from '../vue_modules/MdComponents/MdCombo/MdCombo';
import MdLoadScreen from '../vue_modules/MdComponents/MdLoadScreen/MdLoadScreen';
import sd from 'fb-utils/serviceDiscovery';

export default {
    extends: BaseComponent,
    components: {MdLoadScreen, MdCombo, MdTextInput, MdDialog},
    data: function () {
        return {
            myId: auth.instance.userInfo.Id,
            emailSearchType: auth.instance.userInfo.AdminSettings['users.EmailMatchType'] || 'FTS',
            pageSize: pageSize(),
            language: auth.instance.userInfo.AdminSettings['language'],
            theme: auth.instance.userInfo.AdminSettings['theme'] || 'normal',
            loading: false,
            logout: '',
            href: encodeURIComponent(window.location.href)
        };
    },
    created () {
        sd.instance().GetApi('generic', 'logon-logout').then(url => (this.logout = url));
    },
    methods: {
        doLogout () {
            sessionStorage.clear();
            localStorage.clear();
        },
        save () {
            this.loading = true;
            auth.instance.userInfo.AdminSettings['users.EmailMatchType'] = this.emailSearchType;
            auth.instance.userInfo.AdminSettings['pager.defaultSize'] = this.pageSize;
            const langChanged = this.language !== auth.instance.userInfo.AdminSettings['language'];
            auth.instance.userInfo.AdminSettings['language'] = this.language;
            document.body.dataset.theme = auth.instance.userInfo.AdminSettings.theme = this.theme;
            auth.instance.userInfo.save('settings').then(() => {
                if (langChanged) {
                    document.cookie = 'LanguageOverride=' + this.language + ';max-age=31536000';
                    window.location.reload();
                } else {
                    this.$refs.dialog.close(true);
                }
            });
        }
    }
};
</script>
<style module="buttons" src="../css-mixins/buttons.css"/>
<style module>
.form{}
.form>*{
    margin: .5em 0;
}
</style>
