import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'products',
        'name': 'PriceList',
        'icon': fa.money,
        'path': 'Products/PriceList',
        'handler': 'PriceList',
        'index': 10,
        'requirePermissions': ['Admin']
    },
    {
        'inMainMenu': false,
        'path': 'payments/possibilities',
        'handler': 'PossiblePaymentsComponent',
        'requirePermissions': ['Admin']
    },
    {
        'inMainMenu': false,
        'path': 'payments/process',
        'handler': 'ProcessPaymentComponent',
        'requirePermissions': ['Admin']
    }
];
export {menu};
