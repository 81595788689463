import EnumInstance from './enumInstance';
import _ from 'underscore';

module.exports = function (enumMembers, isFlags) {
    if (!_.isArray(enumMembers)) {
        enumMembers = _.map(enumMembers, (v, k) => {
            v.UnderlayingValue = k;
            return v;
        });
    }
    this.Values = _.uniq(enumMembers, false, x => x.UnderlayingValue);
    this.IsFlags = isFlags || false;
    this.UnderlayingValue = function (name) {
        for (const v in this.Values) {
            const xx = this.Values[v];
            if (xx.Value === name) {
                return xx.UnderlayingValue;
            }
        }
        return undefined;
    };
    this.Parse = function (val) {
        const ret = [];
        if (val === null || val instanceof EnumInstance) { return val; }
        if (Number(val).toString() === val?.toString()) {
            val = Number(val);
            for (const v in this.Values) {
                const xx = this.Values[v];
                xx.UnderlayingValue = Number(xx.UnderlayingValue);
                if (isFlags) {
                    if (xx.UnderlayingValue === 0 && val === 0) {
                        ret.push(xx);
                    } else if ((xx.UnderlayingValue & val) === xx.UnderlayingValue && xx.UnderlayingValue !== 0) {
                        ret.push(xx);
                    }
                } else {
                    if (xx.UnderlayingValue === val) {
                        ret.push(xx);
                    }
                }
            }
        } else {
            let sValue = (val || '').toString();
            if (isFlags) {
                sValue = sValue.split(/,\s*/);
            }
            for (const v in this.Values) {
                const xx = this.Values[v];
                if (isFlags) {
                    if (sValue.indexOf(xx.Value) !== -1) { ret.push(xx); }
                } else if (xx.Value === sValue) {
                    ret.push(xx);
                }
            }
        }
        return new EnumInstance(this, ret);
    };
    this.AsSimpleObject = function (valueTranslationFunc) {
        const rv = {};
        if (!valueTranslationFunc) {
            valueTranslationFunc = x => x;
        }
        for (const v in this.Values) {
            rv[this.Values[v].UnderlayingValue] = valueTranslationFunc(this.Values[v].Value);
        }
        return rv;
    };
    this.AsComboBoxOptions = function (tr) {
        tr = tr || (x => x);
        const rv = {};
        for (const v in this.Values) {
            rv[this.Values[v].Value] = tr(this.Values[v].Value);
        }
        return rv;
    };
    this.Value = function (str) {
        for (const v in this.Values) {
            if (str === this.Values[v].Value) { return this.Values[v].UnderlayingValue; }
        }
    };
};
