<template>
    <div :class="$style.load" :style="{background: bgColor}">
        <gear :class="[$style.gear,$style.one]" fill="#94DDFF"/>
        <gear :class="[$style.gear,$style.two]" fill="#FB8BB9"/>
        <gear :class="[$style.gear,$style.three]" fill="#FFCD5C"/>
        <div :class="$style.lilCircle"></div>
        <svg :class="$style.blurCircle">
            <filter id="md-loadscreen-blur">
                <fegaussianblur in="SourceGraphic" stdDeviation="13"></fegaussianblur>
            </filter>
            <circle cx="70" cy="70" r="66" fill="transparent" :stroke="bgColor" stroke-width="40" filter="#md-loadscreen-blur"></circle>
        </svg>
    </div>

</template>

<script>
import gear from './gear.vue';

export default {
    components: {gear},
    props: ['background'],
    computed: {
        bgColor () {
            return this.background || '#eee';
        }
    }
};
</script>

<style module>
    .load {
        position: relative;
        margin: 50px auto;
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 100px;
    }
    .gear {
        position: absolute;
        width: 100px;
        height: 100px;
        top: -55px;
        left: -22px;
        animation: spin 5s infinite;
    }
    .two {
        top: -4px;
        left: 48px;
        animation: spin-reverse 5s infinite;
    }
    .three {
        top: 43px;
        left: -22px;
    }
    @keyframes spin {
        50% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin-reverse {
        50% {
            transform: rotate(-360deg);
        }
    }
    .lilCircle {
        position: absolute;
        border-radius: 50%;
        box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
        width: 100px;
        height: 100px;
        opacity: 0.65;
    }
    .blurCircle {
        position: absolute;
        top: -19px;
        left: -19px;
    }
    .text {
        color: lightgray;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
    }

</style>
