var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MdLoadScreen",
    { class: _vm.$style.wrapper, attrs: { "is-loading": _vm.doingSearch } },
    [
      _c(
        "div",
        { class: _vm.$style.topbar },
        [
          _c("span", { class: _vm.$style.mobileOnly }, [
            _c("span", {
              class: [_vm.fa.bars, _vm.$style.menuExpander],
              on: {
                click: function ($event) {
                  _vm.menuExpanded = !_vm.menuExpanded
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              class: [_vm.$style.mainTitle, _vm.$style.hideOnMobile],
              attrs: { to: "/" },
            },
            [
              _vm._v("\n            FB"),
              _c("span", { class: _vm.$style.symbol }, [
                _vm._v(_vm._s(_vm.$t("titleSymbol"))),
              ]),
              _vm._v(_vm._s(_vm.$t("title")) + "\n        "),
            ]
          ),
          _vm._v(" "),
          _c("span", { class: _vm.$style.screenTitle }, [
            _vm._v(_vm._s(_vm.screenTitle)),
          ]),
          _vm._v(" "),
          _c("span", { class: _vm.$style.strut }),
          _vm._v(" "),
          _c(
            "form",
            {
              class: _vm.$style.search,
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.doSearch.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "MdTextinput",
                {
                  ref: "searchInput",
                  class: _vm.$style.searchRecolor,
                  attrs: { transparent: true },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("search")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                { class: _vm.$style.searchButton, attrs: { type: "submit" } },
                [_c("span", { class: _vm.fa.search })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { class: _vm.$style.usermenu },
            [
              _c(
                "router-link",
                {
                  class: _vm.$style.usermenulink,
                  attrs: { to: `/User/${_vm.user.Id}/Edit` },
                },
                [
                  _c("img", {
                    class: _vm.$style.avatar,
                    attrs: {
                      src: `https://www.gravatar.com/avatar/${_vm.emailMD5}?s=24`,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  class: [_vm.$style.hideOnMobile, _vm.$style.usermenulink],
                  attrs: { to: `/User/${_vm.user.Id}/Edit` },
                },
                [
                  _vm.user.FirstName || _vm.user.LastName
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.user.FirstName) +
                            "\n                    " +
                            _vm._s(_vm.user.LastName) +
                            "\n                "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.user.Email) +
                            "\n                "
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            class: [_vm.fa.cog, _vm.$style.settings],
            attrs: { title: _vm.$t("settings") },
            on: { click: _vm.showUserMenu },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            [_vm.$style.menu]: true,
            [_vm.$style.menuExpanded]: _vm.menuExpanded,
          },
        },
        [_c("MainMenu")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            [_vm.$style.content]: true,
            [_vm.$style.contentWithMenuExpanded]: _vm.menuExpanded,
          },
        },
        [_c("router-view", { on: { pageTitleChanged: _vm.updateTitle } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.overlay, staticStyle: { "z-index": "1000000" } },
        _vm._l(_vm.overlays, function (overlay) {
          return _c(
            overlay.component,
            _vm._g(
              _vm._b(
                {
                  key: "overlay-" + overlay.id,
                  ref: "overlayInstances",
                  refInFor: true,
                  tag: "component",
                  attrs: { slotContent: overlay.slots },
                  on: {
                    close: function ($event) {
                      return _vm.closeOverlay(overlay, $event)
                    },
                  },
                },
                "component",
                overlay.props,
                false
              ),
              overlay.on
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        ref: "dialogTarget",
        class: _vm.$style.overlay,
        staticStyle: { "z-index": "1000001" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.overlay, staticStyle: { "z-index": "1000002" } },
        [
          _c(
            "transition-group",
            {
              attrs: {
                "enter-class": _vm.$style.messageEnter,
                "enter-active-class": _vm.$style.messageEnterActive,
                "enter-to-class": _vm.$style.messageEnterTo,
                "leave-class": _vm.$style.messageLeave,
                "leave-active-class": _vm.$style.messageLeaveActive,
                "leave-to-class": _vm.$style.messageLeaveTo,
              },
            },
            _vm._l(_vm.messages, function (message) {
              return _c("Message", {
                key: message.id,
                attrs: { message: message },
                on: {
                  dismiss: function ($event) {
                    return _vm.hideMessage(message)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }