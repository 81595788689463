module.exports={"Enums":[{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.AttributeType","Name":"AttributeType","IsFlags":true,"Values":[{"Description":"Continuous","Value":"Continuous","UnderlayingValue":0},{"Description":"Discrete","Value":"Discrete","UnderlayingValue":1},{"Description":"Continuous","Value":"Continuous","UnderlayingValue":0},{"Description":"Text","Value":"Text","UnderlayingValue":2},{"Description":"LongText","Value":"LongText","UnderlayingValue":4},{"Description":"Boolean","Value":"Boolean","UnderlayingValue":8},{"Description":"Time","Value":"Time","UnderlayingValue":16}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.ReportAccess","Name":"ReportAccess","IsFlags":false,"Values":[{"Description":"Public","Value":"Public","UnderlayingValue":0},{"Description":"System","Value":"System","UnderlayingValue":1},{"Description":"Private","Value":"Private","UnderlayingValue":2},{"Description":"Archived","Value":"Archived","UnderlayingValue":3}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.Aggregate","Name":"Aggregate","IsFlags":false,"Values":[{"Description":"sum","Value":"sum","UnderlayingValue":0},{"Description":"max","Value":"max","UnderlayingValue":1},{"Description":"min","Value":"min","UnderlayingValue":2},{"Description":"average","Value":"average","UnderlayingValue":3},{"Description":"string_agg","Value":"string_agg","UnderlayingValue":4},{"Description":"distincts","Value":"distincts","UnderlayingValue":5}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.AxisType","Name":"AxisType","IsFlags":false,"Values":[{"Description":"time","Value":"time","UnderlayingValue":0},{"Description":"count","Value":"count","UnderlayingValue":1},{"Description":"attr","Value":"attr","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.DiscretizationMode","Name":"DiscretizationMode","IsFlags":false,"Values":[{"Description":"auto","Value":"auto","UnderlayingValue":0},{"Description":"manual","Value":"manual","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.JunctionMode","Name":"JunctionMode","IsFlags":false,"Values":[{"Description":"AND","Value":"AND","UnderlayingValue":0},{"Description":"OR","Value":"OR","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.Operator","Name":"Operator","IsFlags":false,"Values":[{"Description":"eq","Value":"eq","UnderlayingValue":0},{"Description":"neq","Value":"neq","UnderlayingValue":1},{"Description":"lt","Value":"lt","UnderlayingValue":2},{"Description":"gt","Value":"gt","UnderlayingValue":3},{"Description":"in","Value":"in","UnderlayingValue":4},{"Description":"en","Value":"en","UnderlayingValue":5},{"Description":"st","Value":"st","UnderlayingValue":6},{"Description":"def","Value":"def","UnderlayingValue":7},{"Description":"else","Value":"else","UnderlayingValue":8}]},
{"FullName":"Mediaparts.Statistics.Server.Frontend.Models.Json.TimeTruncation","Name":"TimeTruncation","IsFlags":false,"Values":[{"Description":"none","Value":"none","UnderlayingValue":0},{"Description":"hour","Value":"hour","UnderlayingValue":1},{"Description":"day","Value":"day","UnderlayingValue":2},{"Description":"week","Value":"week","UnderlayingValue":3},{"Description":"month","Value":"month","UnderlayingValue":4},{"Description":"quarter","Value":"quarter","UnderlayingValue":5},{"Description":"year","Value":"year","UnderlayingValue":6}]},
{"FullName":"MaxMind.Db.ObjectType","Name":"ObjectType","IsFlags":false,"Values":[{"Description":"Extended","Value":"Extended","UnderlayingValue":0},{"Description":"Pointer","Value":"Pointer","UnderlayingValue":1},{"Description":"Utf8String","Value":"Utf8String","UnderlayingValue":2},{"Description":"Double","Value":"Double","UnderlayingValue":3},{"Description":"Bytes","Value":"Bytes","UnderlayingValue":4},{"Description":"Uint16","Value":"Uint16","UnderlayingValue":5},{"Description":"Uint32","Value":"Uint32","UnderlayingValue":6},{"Description":"Map","Value":"Map","UnderlayingValue":7},{"Description":"Int32","Value":"Int32","UnderlayingValue":8},{"Description":"Uint64","Value":"Uint64","UnderlayingValue":9},{"Description":"Uint128","Value":"Uint128","UnderlayingValue":10},{"Description":"Array","Value":"Array","UnderlayingValue":11},{"Description":"Container","Value":"Container","UnderlayingValue":12},{"Description":"EndMarker","Value":"EndMarker","UnderlayingValue":13},{"Description":"Boolean","Value":"Boolean","UnderlayingValue":14},{"Description":"Float","Value":"Float","UnderlayingValue":15}]},
{"FullName":"MaxMind.Db.FileAccessMode","Name":"FileAccessMode","IsFlags":false,"Values":[{"Description":"MemoryMapped","Value":"MemoryMapped","UnderlayingValue":0},{"Description":"Memory","Value":"Memory","UnderlayingValue":1}]},
{"FullName":"Mediaparts.Portal.Core.Utility.RetryExceptionType","Name":"RetryExceptionType","IsFlags":false,"Values":[{"Description":"Retriable","Value":"Retriable","UnderlayingValue":0},{"Description":"Ignorable","Value":"Ignorable","UnderlayingValue":1},{"Description":"Unrecoverable","Value":"Unrecoverable","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Utility.NodeType","Name":"NodeType","IsFlags":false,"Values":[{"Description":"Root","Value":"Root","UnderlayingValue":0},{"Description":"Internal","Value":"Internal","UnderlayingValue":1},{"Description":"Leaf","Value":"Leaf","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.Domain.Validation.WellKnownValidationErrors","Name":"WellKnownValidationErrors","IsFlags":false,"Values":[{"Description":"ValueMandatory","Value":"ValueMandatory","UnderlayingValue":0},{"Description":"ValueTooShort","Value":"ValueTooShort","UnderlayingValue":1},{"Description":"ValueTooLong","Value":"ValueTooLong","UnderlayingValue":2},{"Description":"ValueNotUnique","Value":"ValueNotUnique","UnderlayingValue":3},{"Description":"ValueExceedsMinimum","Value":"ValueExceedsMinimum","UnderlayingValue":4},{"Description":"ValueExceedsMaximum","Value":"ValueExceedsMaximum","UnderlayingValue":5},{"Description":"ValueWrongData","Value":"ValueWrongData","UnderlayingValue":6}]},
{"FullName":"Mediaparts.Portal.Core.RemoteAppenderModels.LoggingAction","Name":"LoggingAction","IsFlags":false,"Values":[{"Description":"Store","Value":"Store","UnderlayingValue":0},{"Description":"Transmit","Value":"Transmit","UnderlayingValue":1},{"Description":"Discard","Value":"Discard","UnderlayingValue":2}]},
{"FullName":"Mediaparts.Portal.Core.CMS.CMSController+RedirectReason","Name":"RedirectReason","IsFlags":false,"Values":[{"Description":"Default","Value":"Default","UnderlayingValue":0},{"Description":"Canonicalization","Value":"Canonicalization","UnderlayingValue":1},{"Description":"Alias","Value":"Alias","UnderlayingValue":2}]}],"ScanTime":"0:00:00.0331759","GenTime":"0:00:00.0006726"};