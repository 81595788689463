
module.exports = function (num, precision, radix, suffixes) {
    radix = radix || 1000;
    suffixes = suffixes || ['', 'k', 'm', 'g', 't'];
    let sign = '';
    if (num < 0) {
        num = Math.abs(num);
        sign = '-';
    }
    let e = 0;
    while (e < suffixes.length && num > Math.pow(radix, e + 1)) {
        e++;
    }
    return sign + (num / Math.pow(radix, e)).toFixed(precision) + suffixes[e];
};
