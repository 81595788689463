import fa from '../../css-mixins/fontawesome.css';
export function load (callback) { require.ensure(['./module'], function (require) { callback(require('./module')); }); }
const menu = [
    {
        'inMainMenu': true,
        'category': 'content',
        'name': 'Statistics',
        'icon': fa['bar-chart'],
        'path': 'Statistics',
        'handler': 'Reports',
        'index': 100,
        'requirePermissions': ['Admin.Statistics']
        // 'extraPaths': ['Statistics/.*']
    },
    {
        'path': 'Statistics/:id/edit',
        'handler': 'EditReport'
    },
    {
        'path': 'Statistics/:id',
        'handler': 'Report'
    }
];
export {menu};
